import axios from 'axios';

const axiosBase = (method, path, data = {}, headers = {}) => {
  return new Promise((resolve, reject) => {
    axios({
      method: method, // HTTP method: GET, POST, PUT, DELETE, etc.
      url: `https://api.myaimgenius.com/${path}`, // API endpoint
      data: data, // Data to be sent in case of POST/PUT requests
      headers: {
        'Content-Type': 'application/json', // Default header
        ...headers // Additional headers, like Authorization
      }
    })
    .then(response => {
      resolve(response.data); // Resolve with the response data
    })
    .catch(error => {
      console.error("API Error: ", error); // Log error to console
      reject(error.response ? error.response.data : { message: 'An error occurred' });
    });
  });
};

export default axiosBase;
