import { Button, Divider, Select, Input, Space, Badge, Descriptions, message, Modal, Form, InputNumber, DatePicker } from 'antd';
import { useState, useRef } from 'react';
import axios from 'axios';
import { addPayment, searchStudent } from '../../../Helpers/requests/student';

const { Option } = Select;
function convertDate(isoString) {
  const date = new Date(isoString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();   

  return `${day}-${month}-${year}`;   

}
const Payments = () => {
  const [loaded, setLoaded] = useState(false);
  const [studentData, setStudentData] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchType, setSearchType] = useState("id");
  const [activeTab, setActiveTab] = useState("information");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [installmentNumber, setInstallmentNumber] = useState(false);
  const [amount, setAmount] = useState(false);
  const [expectedAmount, setExpectedAmount] = useState(false);
  const [paymentDate, setPaymentDate] = useState(false);
  const [totalPresent, setTotalPresent] = useState(0);
  const [totalLate, setTotalLate] = useState(0);
  const [totalAbsent, setTotalAbsent] = useState(0);
  const [form] = Form.useForm();
  const printRef = useRef();

  const items = [
    { key: '1', label: 'Name', children: studentData?.firstName + ' ' + studentData?.lastName || '' },
    { key: '2', label: 'ID Number', children: studentData?.studentID || '' },
    { key: '3', label: 'Admission Date', children: convertDate(studentData?.admissionDate) || '' },
    { key: '4', label: 'Phone', children: studentData?.phone || '' },
    { key: '5', label: 'Email', children: studentData?.email || '' },
    { key: '6', label: 'Gender', children: studentData?.gender || '' },
    { key: '7', label: 'Present Address', children: studentData?.presentAddress || '' },
    { key: '8', label: 'Permanent Address', children: studentData?.permanentAddress || '' },
    { key: '9', label: 'Guardian Phone', children: studentData?.guardianPhoneNumber || '' },
    { key: '10', label: 'Relation With Guardian', children: studentData?.relationWithGuardian || '' },
    { key: '11', label: 'Batch', children: studentData?.batchId || '' },
    { key: '13', label: 'Discount', children: studentData?.discount || '' },
    { key: '15', label: 'Status', children: <Badge status="processing" text={studentData?.status || 'Running'} /> },
    { key: '16', label: 'Exams', children: studentData?.results || 'N/A' },
    { key: '17', label: 'Payments', children: studentData?.payments?.map(payment => (
        <>
          Installment {payment.installmentNumber}: <b>৳{payment.amount} </b>- {convertDate(payment.paymentDate) || 'Due'}
          <br />
        </>
      )) || 'No Payments'
    },
    { key: '18', label: 'Additional Note', children: studentData?.additionalNote || '' }
  ];

  const selectAfter = (
    <Select defaultValue="id" onChange={(value) => setSearchType(value)}>
      <Option value="id">ID Number</Option>
      <Option value="name">Name</Option>
      <Option value="mobile">Mobile</Option>
      <Option value="email">Email</Option>
    </Select>
  );

  const handleSearch = async () => {

    try {
      const student = await searchStudent(searchQuery, searchType);
      setStudentData(student);
      setLoaded(true);
    } catch (error) {
      message.error("Student not found");
      setLoaded(false);
      setStudentData(null);
    }
  };

  const handleAddPayment = async () => {

    const values = form.validateFields();
    const updatedPayments = [
      ...studentData.payments,
      { installmentNumber: values.installmentNumber, amount: values.amount, paymentDate: values.paymentDate }
    ];
    addPayment(studentData.studentID, amount, installmentNumber, paymentDate, expectedAmount).then((e)=>{
      setStudentData({ ...studentData, payments: updatedPayments });
      setIsModalVisible(false);

      handleSearch()
      message.success('Payment added successfully');
    }).catch((e)=>{
      message.error('Error adding payment');
    })

  };


  return (
    <div className="p-4">
      <style
           dangerouslySetInnerHTML={{
             __html:
               "\n    :root {\n        --main-color: #4a76a8;\n    }\n\n    .bg-main-color {\n        background-color: var(--main-color);\n    }\n\n    .text-main-color {\n        color: var(--main-color);\n    }\n\n    .border-main-color {\n        border-color: var(--main-color);\n    }\n"
           }}
         />
         <link
           href="https://unpkg.com/tailwindcss@^1.0/dist/tailwind.min.css"
           rel="stylesheet"
         />
         <div className="bg-white">
           <div className="container mx-auto my-5 p-5">
           <div className="flex">
        <div className="w-1/2">
          <Input 
            className="mb-4 w-1/2 mr-3" 
            addonAfter={selectAfter} 
            placeholder="Enter search value" 
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <Button onClick={handleSearch}>Search</Button>
        </div>
        <div className="w-1/2">
          <Button disabled={!loaded} className="mx-2" type="primary" onClick={() => setIsModalVisible(true)}>Add Payment</Button>
          {/* <Divider type="vertical" />
          <Button disabled={!loaded} className="mx-2" onClick={handlePrint}>Print Receipt</Button> */}
        </div>
      </div>
      



      {studentData && (
   
   <div className="md:flex no-wrap md:-mx-2">
        {/* Left Side */}
        <div className="w-full md:w-3/12 md:mx-2">
          {/* Profile Card */}
          <div className="bg-white p-3 border-t-4 shadow-lg rounded-lg border-green-400">
            <h1 className="text-gray-900 font-bold text-xl leading-8 my-1">
              {studentData?.firstName} {studentData?.lastName}
            </h1>
            <h3 className="text-gray-600 font-lg text-semibold leading-6">
              Student at {studentData?.batchId}
            </h3>
            <p className="text-sm text-gray-500 hover:text-gray-600 leading-6">
              {studentData?.additionalNote || "No additional notes"}
            </p>
            <ul className="bg-gray-100 text-gray-600 hover:text-gray-700 hover:shadow py-2 px-3 mt-3 divide-y rounded shadow-sm">
              <li className="flex items-center py-3">
                <span>Status</span>
                <span className="ml-auto">
                  <span className="bg-green-500 py-1 px-2 rounded text-white text-sm">
                    {studentData?.status || 'Running'}
                  </span>
                </span>
              </li>
              <li className="flex items-center py-3">
                <span>Admission Date</span>
                <span className="ml-auto">{convertDate(studentData?.admissionDate) || ''}</span>
              </li>
            </ul>

            <br></br>
            <Divider></Divider>
          </div>
          {/* End of profile card */}
        </div>
        {/* Right Side with Tabs */}
        <div className="w-full rounded-lg border-t-4 border-green-400 shadow-xl md:w-9/12 mx-2 h-64">
          <div className="bg-white p-3 shadow-lg rounded-lg">
            <div className="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
              <span className="text-green-500">
                <svg
                  className="h-5"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                  />
                </svg>
              </span>
              <span className="tracking-wide">Student Information</span>
            </div>
            {/* Tab Navigation */}
            <div className="tabs">
              <button
                className={`tab ${activeTab === 'information' ? 'active bg-gray-100' : ''} p-3 bg-white text-green-500 border-b-4 border-orange-500 m-3 hover:border-green-500 hover:text-black duration-100 transition  mb-6`}
                onClick={() => setActiveTab('information')}
              >
                Information
              </button>
              <button
                className={`tab ${activeTab === 'fees' ? 'active bg-gray-100' : ''} p-3 bg-white text-green-500 border-b-4 border-orange-500 m-3 hover:border-green-500 hover:text-black duration-100 transition  mb-6`}
                onClick={() => setActiveTab('fees')}
              >
                Fees
              </button>
              <button
                className={`tab ${activeTab === 'attendance' ? 'active bg-gray-100' : ''} p-3 bg-white text-green-500 border-b-4 border-orange-500 m-3 hover:border-green-500 hover:text-black duration-100 transition  mb-6`}
                onClick={() => setActiveTab('attendance')}
              >
                Attendance
              </button>
            </div>
            {/* Tab Content */}
            {activeTab === 'information' && (
              <div className="tab-content">
                 <div className="grid md:grid-cols-2 text-sm">
                          <div className="grid grid-cols-2">
                            <div className="px-4 py-2 font-semibold">ID Number</div>
                            <div className="px-4 py-2">{studentData?.studentID || ''}</div>
                          </div>
                          <div className="grid grid-cols-2">
                            <div className="px-4 py-2 font-semibold">Gender</div>
                            <div className="px-4 py-2">{studentData?.gender || ''}</div>
                          </div>
                          <div className="grid grid-cols-2">
                            <div className="px-4 py-2 font-semibold">Contact No.</div>
                            <div className="px-4 py-2">{studentData?.phone || ''}</div>
                          </div>
                          <div className="grid grid-cols-2">
                            <div className="px-4 py-2 font-semibold">Email</div>
                            <div className="px-4 py-2">
                              <a className="text-blue-800" href={`mailto:${studentData?.email}`}>
                                {studentData?.email}
                              </a>
                            </div>
                          </div>
                          <div className="grid grid-cols-2">
                            <div className="px-4 py-2 font-semibold">Present Address</div>
                            <div className="px-4 py-2">{studentData?.presentAddress || ''}</div>
                          </div>
                          <div className="grid grid-cols-2">
                            <div className="px-4 py-2 font-semibold">Permanent Address</div>
                            <div className="px-4 py-2">{studentData?.permanentAddress || ''}</div>
                          </div>
                        </div>
              </div>
            )}

            {activeTab === 'fees' && (
              <div className="tab-content">
                <div className="grid grid-cols-1">
                  <div>
                    <div className="flex items-center space-x-2 font-semibold text-gray-900 leading-8 mb-3">
                      <span className="text-green-500">
                        <svg
                          className="h-5"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                          />
                        </svg>
                      </span>
                      <span className="tracking-wide">Payments</span>
                    </div>
                    <ul className="list-inside space-y-2">
                      {studentData?.payments?.map((payment, index) => (
                        <li key={index}>
                          <div className="text-teal-600">
                            Installment {payment.installmentNumber}: 
                            <b> ৳{payment.amount}</b>
                          </div>
                          <div className="text-gray-500 text-xs">
                            {convertDate(payment.paymentDate) || 'Due'}
                          </div>
                        </li>
                      )) || <li>No Payments</li>}
                    </ul>
                  </div>
                </div>
              </div>
            )}

            {activeTab === 'attendance' && (
              <div className="tab-content">
                {/* Attendance Statistics */}
                <div className="grid grid-cols-3 gap-4 mb-4">
                  <div className="bg-white p-3 shadow-lg rounded-lg">
                    <div className="text-green-500 text-2xl font-bold">
                      {totalPresent}
                    </div>
                    <div className="text-gray-600">Total Present</div>
                  </div>
                  <div className="bg-white p-3 shadow-lg rounded-lg">
                    <div className="text-orange-500 text-2xl font-bold">
                      {totalLate}
                    </div>
                    <div className="text-gray-600">Total Late</div>
                  </div>
                  <div className="bg-white p-3 shadow-lg rounded-lg">
                    <div className="text-red-500 text-2xl font-bold">
                      {totalAbsent}
                    </div>
                    <div className="text-gray-600">Total Absent</div>
                  </div>
                </div>
                {/* Attendance Details */}
                <ul className="list-inside space-y-2">
                  {studentData?.attendances?.map((attendance, index) => (
                    <li key={index}>
                      <div className={`text-sm ${attendance.status === 'Present' ? 'text-green-500' : attendance.status === 'Late' ? 'text-orange-500' : 'text-red-500'}`}>
                        {convertDate(attendance.date)} - {attendance.status}
                      </div>
                    </li>
                  )) || <li>No Attendances</li>}
                </ul>
              </div>
            )}

            <br></br>
            <Divider></Divider>
          </div>
        </div>
      </div>
          
           )}




           </div>
         </div>
   
   

      <Modal
        title="Add Payment"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleAddPayment}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="amount"
            label="Payment Amount"
            rules={[{ required: true, message: 'Please input the payment amount!' }]}
          >
            <InputNumber className="w-full" onChange={(e)=>{
              setAmount(e)
            }} min={0} />
          </Form.Item>
          <Form.Item
            name="expectedAmoung"
            label="Expected Amount to pay"
            rules={[{ required: true, message: 'Please input the expected amount!' }]}
          >
            <InputNumber className="w-full" onChange={(e)=>{
              setExpectedAmount(e)
            }} min={0} />
          </Form.Item>
          <Form.Item
            name="installmentNumber"
            label="Installment Number"
            rules={[{ required: true, message: 'Please input the installment number!' }]}
          >
            <InputNumber  onChange={(e)=>{
              setInstallmentNumber(e)
            }}
            className="w-full" min={1} />
          </Form.Item>
          <Form.Item
            name="paymentDate"
            label="Payment Date"
            rules={[{ required: true, message: 'Please select the payment date!' }]}
          >
            <DatePicker  onChange={(e)=>{
              setPaymentDate(e.format("DD/MM/YYYY"))
            }}
            className="w-full" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Payments;



