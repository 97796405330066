import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "tailwindcss/tailwind.css";
import { getLeaderboard, updateLeaderboard } from "../../../Helpers/firebase";
import { message } from "antd";

const Leaderboard = () => {
  const [students, setStudents] = useState({0: [], 1: [], 2:[], 3:[]});
  const sA = [0, 1, 2, 3]
  const fetchLeaderboard = async () => {
    const data = await getLeaderboard();
    setStudents(data.newOrder);
  };
  useEffect(() => {
    

    fetchLeaderboard();
  }, []);

  const handleDragEnd = (result, index) => {
    if (!result.destination) return;

    const reordered = Array.from(students[index]);
    const [moved] = reordered.splice(result.source.index, 1);
    reordered.splice(result.destination.index, 0, moved);
    const p = students
    p[index] = reordered
    setStudents(p);
    handleDragSave(p)
  };

  const handleInputChange = (index, id, field, value) => {
    const updatedStudents = students[index].map((student) =>
      student.id === id ? { ...student, [field]: value } : student
    );
    students[index] = updatedStudents
    setStudents(students);
    handleSave(index)
  };

  const handleDragSave = async (data) => {
    try {
      await updateLeaderboard(data);
      message.success("Leaderboard saved!");
      fetchLeaderboard();
    } catch (error) {
      console.log(error)
      message.error("Failed to save leaderboard.");
    }
  };
  const handleSave = async (index) => {
    try {
      await updateLeaderboard({ [index]:  students[index]});
      message.success("Leaderboard saved!");
      fetchLeaderboard();
    } catch (error) {
      message.error("Failed to save leaderboard.");
    }
  };

  return (
    <div className="rounded-xl m-6 bg-gray-100 p-6 flex flex-col items-center">
      <h1 className="text-3xl font-bold mb-6">Leaderboard Editor</h1>
    <div className="grid grid-cols-4 gap-4">
      {sA.map((m,n )=> {
        
  return( 
  <DragDropContext onDragEnd={(e)=>{handleDragEnd(e, m)}}>
    <Droppable droppableId={`students ${n}`}>
      {(provided) => (
        <div
          className="w-full max-w-xl bg-white rounded-lg shadow-md p-4"
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          <p className="font-bold"> Batch Name: {m === 0 ? "A1" : m === 1 ? "A1-X" :m === 2 ? "A2" : m === 3 ? "B1" : ":)"} </p>
          {students[m].map((student, index) => (
            <Draggable
              key={student.id}
              draggableId={student.id}
              index={index}
            >
              {(provided) => (
                <div
                  className="flex items-center justify-between p-4 mb-2 bg-gray-50 rounded-lg shadow-sm"
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  <div className="flex items-center space-x-4">
                    <span className="text-lg font-semibold">{index + 1}.</span>
                   <form onSubmit={(e)=>{
                    e.preventDefault()
                    handleInputChange(m, student.id, "name", e.target[0].value)
                   }}>
                   <input
                      type="text"
                      defaultValue={student.name}
                     
                      className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                      placeholder="Name"
                    />
                   </form>
                  </div>
                  <form onSubmit={(e)=>{
                    e.preventDefault()
                    handleInputChange(m, student.id, "points", e.target[0].value)
                   }}>
                 <input
                    type="number"
                    defaultValue={student.points}
                    className="w-20 border border-gray-300 rounded-lg p-2 text-center focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Points"
                  />
                   </form>
                  
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  </DragDropContext>)
      })}
     

   
    
    </div>
    </div>
  );
};

export default Leaderboard;
