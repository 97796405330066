import axiosBase from "./base";


export const createBatch = (name, totalFee, installments, id) => {
    return new Promise((res, rej)=>{
        axiosBase("post", "admin/batch", {
                name: name,
                totalFee: totalFee,
                installments: installments,
                id: id
        }).then((e)=>{
            res(e)
        }).catch((e)=>{
            rej(e)
        })
    })
}


export const getAllBatchesWithStudents = () => {
    return new Promise((res, rej)=>{
        axiosBase("get", 'admin/batches').then((e)=>{
            res(e)
        }).catch((e)=>{
            rej(e)
        })
    })
}
export const getStudentsBatch = (batchID) => {
    return new Promise((res, rej)=>{
        axiosBase("get", `admin/batch/${batchID}`).then((e)=>{
            res(e)
        }).catch((e)=>{
            rej(e)
        })
    })
}
export const deleteBatch = (batchID) => {
    return new Promise((res, rej)=>{
        axiosBase("delete", `admin/batch/${batchID}`).then((e)=>{
            res(e)
        }).catch((e)=>{
            rej(e)
        })
    })
}

export const addStudentToBatch = (batchID, updatedBatch) => {
    return new Promise((res, rej)=>{
        // axiosBase("get", )
        axiosBase("put", `admin/batch/${batchID}`, updatedBatch)
    })
}

export const updateBatch = (batchID, update ) => {
    return new Promise((res, rej)=>{
        // axiosBase("get", )
        axiosBase("put", `admin/batch/${batchID}`, update).then((e)=>{
            res(e)
        }).catch((e)=>{
            rej(e)
        })
    })
}

