import { useState } from "react";
import AdminHome from "./Components/Home";
import AdmissionPage from "./Components/Admission";
import Batches from "./Components/Batches";
import Students from "./Components/Students";
import Payments from "./Components/Payments";
import Expenses from "./Components/Expenses";
import { logout } from "../../Helpers/firebase";
import Teachers from "./Components/Teachers";
import { Drawer, Form, Input, message, Select } from "antd";
import { searchStudent } from "../../Helpers/requests/student";
import StudentInfo from "./Components/StudentInfo";
import logo from './logo.png'
import { FaHome, FaUsers, FaChalkboardTeacher, FaClipboardList, FaFileInvoiceDollar, FaListAlt, FaRegistered, FaPeopleArrows } from 'react-icons/fa';
import Leaderboard from "./Components/Leaderboard";
import Registrations from "./Components/Registrations";

const { Option } = Select;

const AdminDashboard = () => {
    const [currentPage, setCurrentPage] = useState("Home");
    const [ gt, setGT] = useState("")
    const [ searchType, setSearchType] = useState("name")
    const [ stf, setStf ] = useState({})

    const handleSearch = async () => {
      try {
        const student = await searchStudent(gt,searchType);
        setStf(student)
        setCurrentPage("StudentInfo");
        // setLoaded(true);
      } catch (error) {
        message.error("Student not found");
      }
    };

    const selectAfter = (
      <Select defaultValue="id" onChange={(value) => setSearchType(value)}>
        <Option value="name">Name</Option>
        <Option value="id">ID Number</Option>
        <Option value="mobile">Mobile</Option>
        <Option value="email">Email</Option>
      </Select>
    );
    return (

      <>
 



<div className="flex h-screen">
            {/* Left Sidebar */}
            <div className="w-full sm:w-1/4 lg:w-1/6 mx-4 my-6 rounded-xl bg-[#343a40] p-4 shadow-lg">
  <ul className="space-y-4">
    {[
      { label: 'Home', page: 'Home', icon: <FaHome /> },
      { label: 'Batches', page: 'Batches', icon: <FaClipboardList /> },
      { label: 'Students', page: 'Students', icon: <FaUsers /> },
      { label: 'Teachers', page: 'Teachers', icon: <FaChalkboardTeacher /> },
      { label: 'Finance', page: 'Expenses', icon: <FaFileInvoiceDollar /> },
      { label: 'Leaderboard', page: 'Leaderboard', icon: <FaListAlt /> },
      { label: 'Registrations', page: 'Registrations', icon: <FaPeopleArrows   /> }
    ].map((item, index) => (
      <li key={index}>
        <p
          onClick={() => setCurrentPage(item.page)}
          className={`flex items-center space-x-3 cursor-pointer text-sm md:text-base font-medium p-3 rounded-lg transition-all ${
            currentPage === item.page
              ? 'bg-white text-[#343a40] shadow-md'
              : 'text-white hover:bg-[#FF6347] hover:text-[#343a40]'
          }`}
        >
          <span className="text-xl">{item.icon}</span>
          <span>{item.label}</span>
        </p>
      </li>
    ))}
  </ul>
</div>

            {/* Right Content Area */}
         
            <div className="w-5/6  p-6 bg-white ">
            <nav className="bg-[#343a40] drop-shadow-lg w-full lg:w-[80vw] rounded-xl flex justify-between items-center mx-4 px-8 h-20">
  {/* Logo */}
  <div className="inline-flex">
    <img src={logo} className="w-28" alt="Logo" />
  </div>
  {/* End Logo */}

  {/* Search Bar */}
  <div className="hidden sm:flex items-center justify-start flex-grow px-2">
    <form onSubmit={(e) => {
        e.preventDefault();
        handleSearch(gt);
      }} 
      className="flex items-center w-full"
    >
      <input
        type="text"
        name="query"
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleSearch(gt);
          }
        }}
        onChange={(e) => setGT(e.target.value)}
        placeholder="Search Student Name"
        className="w-full max-w-[15vw] bg-transparent border-2 border-gray-300 rounded-2xl px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
        style={{ backgroundColor: "white" }}
      />
    </form>
  </div>
  {/* End Search Bar */}

  {/* Login / Logout */}
  <div className="flex items-center">
    <a 
      onClick={() => logout()}
      className="py-2 px-4 mr-2 bg-blue-500 hover:bg-blue-600 text-white rounded-full transition duration-300"
      href="#"
    >
      Google Classroom
    </a>
    <a 
      onClick={() => logout()}
      className="py-2 px-4 bg-red-500 hover:bg-red-600 text-white rounded-full transition duration-300"
      href="#"
    >
      Logout
    </a>
  </div>
  {/* End Login */}
</nav>

                {currentPage === "Home" && <AdminHome />}
                {currentPage === "Admission" && <AdmissionPage />}
                {currentPage === "Batches" && <Batches />}
                {currentPage === "StudentInfo" && <StudentInfo studentData={stf}/>}
                {currentPage === "Students" && <Students queryS={gt} />}
                {currentPage === "Payment" && <Payments />}
                {currentPage === "Expenses" && <Expenses />}
                {currentPage === "Teachers" && <Teachers />}
                {currentPage === "Leaderboard" && <Leaderboard />}
                {currentPage === "Registrations" && <Registrations />}
            </div>
        </div>

      </>
    
    );
};

export default AdminDashboard;
