import React, { useState, useEffect } from 'react';
import { Space, Table, Tag, Button, Modal, Input, Popconfirm, message, DatePicker, ConfigProvider } from 'antd';
import axios from 'axios';
import { DeleteStudent, getAllStudents, searchStudent, updateStudent } from '../../../Helpers/requests/student';
import moment from 'moment';
import AdmissionPage from './Admission';
import StudentInfo from './StudentInfo';

const { Column, ColumnGroup } = Table;

const Students = ({queryS}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState(queryS !== undefined ? queryS : "");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalAdmissionVisible, setIsModalAdmissionVisible] = useState(false);
  const [isModalViewVisible, setisModalViewVisible] = useState(false);
  const [isModalViewContent, setisModalViewContent] = useState(<></>);
  const [selectedStudent, setSelectedStudent] = useState(null);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [batchId, setBatchId] = useState('');
  const [gender, setGender] = useState('');
  const [presentAddress, setPresentAddress] = useState('');
  const [permanentAddress, setPermanentAddress] = useState('');
  const [guardianPhoneNumber, setGuardianPhoneNumber] = useState('');
  const [relationWithGuardian, setRelationWithGuardian] = useState('');
  const [additionalNote, setAdditionalNote] = useState('');
  const [discount, setDiscount] = useState('');
  const [admissionDate, setAdmissionDate] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    getAllStudents().then((response) => {
      setData(response);
      setLoading(false);
    }).catch((error) => {
      message.error("Failed to load students");
      setLoading(false);
    });
  };

  const handleDelete = async (id) => {
    message.loading(`Deleting ${id}`)
    DeleteStudent(id).then(() => {
      setData(data.filter(student => student._id !== id));
      message.success("Student deleted successfully");
      setTimeout((e)=>{
        message.destroy()
      }, 500)
    }).catch(() => {
      message.error("Failed to delete student");
    });
  };

  const handleEdit = async () => {
    const updatedStudent = {
      firstName,
      lastName,
      email,
      phone,
      batchId,
      gender,
      presentAddress,
      permanentAddress,
      guardianPhoneNumber,
      relationWithGuardian,
      additionalNote,
      discount,
      admissionDate,
    };

    updateStudent(selectedStudent._id, updatedStudent).then((e)=>{
      fetchData();
      setIsModalVisible(false);
      message.success("Student updated successfully");
    }).catch((e)=>{
      message.error("Failed to update student");

    })

  };

  const handleSearch = () => {

    return data.filter(
      (student) =>
        student.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        student.studentID.toString().includes(searchQuery)
    );
  };

  const showModal = (student) => {
    setSelectedStudent(student);
    setIsModalVisible(true);
    setFirstName(student.firstName);
    setLastName(student.lastName);
    setEmail(student.email);
    setPhone(student.phone);
    setBatchId(student.batchId);
    setGender(student.gender);
    setPresentAddress(student.presentAddress);
    setPermanentAddress(student.permanentAddress);
    setGuardianPhoneNumber(student.guardianPhoneNumber);
    setRelationWithGuardian(student.relationWithGuardian);
    setAdditionalNote(student.additionalNote);
    setDiscount(student.discount);
    setAdmissionDate(moment(student.admissionDate));
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className='p-4'>
      <div className=' flex  mb-4 '>
    
     <input

placeholder="Search by Name or ID"
value={searchQuery}
onChange={(e) => setSearchQuery(e.target.value)}
style={{ marginBottom: 20 }}
className=" cusIn bg-[#00000000]  flex-shrink w-[15vw] mr-auto mt-2 border rounded-2xl px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"

/>
    
  
<div className="flex  rounded-xl  w-1/3 bg-[#343a40] items-center justify-between ">
      <div>
        <h3 className="text-lg font-semibold ml-4 text-white">Student List</h3>
      </div>
      <div className="flex  ml-auto flex-col gap-2 shrink-0 sm:flex-row">
        
        <button onClick={(e)=>{
          setIsModalAdmissionVisible(true)
        }} className="flex select-none ml-auto items-center gap-2 rounded-xl bg-white mr-4 py-2.5 px-4 text-xs font-semibold text-[#343a40] shadow-md shadow-slate-900/10 transition-all hover:shadow-lg hover:shadow-slate-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none">
          {/* Add your SVG icon here */}
         + Admission
        </button>
      </div>
    </div>
   
  </div>
  
  <div className="relative flex flex-col w-full h-full  text-slate-700 bg-white shadow-md rounded-xl bg-clip-border">

  <div className="p-0 overflow-scroll">
    <table className="w-full  text-left table-auto min-w-max">
      <thead>
        <tr>
          <th className="p-4 transition-colors cursor-pointer border-y border-slate-200 bg-slate-50 hover:bg-slate-100">Member</th>
          <th className="p-4 transition-colors cursor-pointer border-y border-slate-200 bg-slate-50 hover:bg-slate-100">ID</th>
          <th className="p-4 transition-colors cursor-pointer border-y border-slate-200 bg-slate-50 hover:bg-slate-100">Batch</th>
          <th className="p-4 transition-colors cursor-pointer border-y border-slate-200 bg-slate-50 hover:bg-slate-100">Actions</th>
        </tr>
      </thead>
      <tbody>
        {handleSearch().map(record => (
          <tr key={record._id}>
            <td className="p-4 border-b border-slate-200">
              <div className="flex items-center gap-3">
               <div className="flex flex-col">
                  <p onClick={(e) => {
                    searchStudent(record.firstName, "name").then((e)=>{
                      setisModalViewContent(<StudentInfo studentData={e}/>)
                      setisModalViewVisible(true)
                    })
                  }} className="text-sm font-semibold text-slate-700 cursor-pointer hover:text-blue-600 ">{`${record.firstName} ${record.lastName}`}</p>
                  <p className="text-sm text-slate-500">{record.email}</p>
                </div>
              </div>
            </td>
            <td className="p-4 border-b border-slate-200">
              <div className="flex flex-col">
                <p className="text-sm text-slate-500">{record.studentID}</p>
              </div>
            </td>
            <td className="p-4 border-b border-slate-200">
              <div className="w-max">
                <div className={`relative grid items-center px-2 py-1 font-sans text-xs font-bold uppercase rounded-md select-none whitespace-nowrap ${record.status === 'online' ? 'bg-green-500/20 text-green-900' : 'bg-slate-100 text-slate-500'}`}>
                  <span>{record.batchId}</span>
                </div>
              </div>
            </td>
            
            <td className="p-4 border-b border-slate-200">
              <Space size="middle">
                <a onClick={() => showModal(record)}>Edit</a>
                <Popconfirm title={`Are you sure you want to delete ${record.firstName}?`} onConfirm={() => handleDelete(record._id)} okText="Yes" cancelText="No">
                  <a>Delete</a>
                </Popconfirm>
              </Space>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  <div className="flex items-center justify-between p-3">
    <br></br>
    <br></br>
    {/* <p className="block text-sm text-slate-500">Page 1 of 10</p>
    <div className="flex gap-1">
      <button className="rounded border border-slate-300 py-2.5 px-3 text-center text-xs font-semibold text-slate-600 transition-all hover:opacity-75 focus:ring focus:ring-slate-300 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none">
        Previous
      </button>
      <button className="rounded border border-slate-300 py-2.5 px-3 text-center text-xs font-semibold text-slate-600 transition-all hover:opacity-75 focus:ring focus:ring-slate-300 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none">
        Next
      </button>
    </div> */}
  </div>
</div>



      <ConfigProvider
  theme={{
    components: {
      Modal: {
        contentBg: "#343a40",
        titleColor:"#fff",
        footerBg: "#343a40",
        headerBg: "#343a40",
      },
    },
  }}
>

<Modal  className='cModal' width={window.innerWidth * 0.4} closeIcon={<></>} closable={false} onCancel={(e)=>{setIsModalAdmissionVisible(false); fetchData()}} footer={<></>} open={isModalAdmissionVisible}>
     <AdmissionPage modal={setIsModalAdmissionVisible}/>

      </Modal>
    
<Modal  width={window.innerWidth * 0.7} closable={true} onCancel={(e)=>{setisModalViewVisible(false); fetchData()}} footer={<></>} open={isModalViewVisible}>
    <div className='h-[75vh] bg-[#343a40]'>
    {isModalViewContent}
    </div>
   
      </Modal>
<Modal
  title="Edit Student"
  width={'60vw'}
  visible={isModalVisible}
  onOk={handleEdit}
  onCancel={handleCancel}
  footer={[
    <Button key="back" onClick={handleCancel}>
      Cancel
    </Button>,
    <Button key="submit" type="primary" onClick={handleEdit}>
      Save Changes
    </Button>,
  ]}
  className=' rounded-xl'
  bodyStyle={{
    backgroundColor: '#343a40', // Dark background for the modal
    color: '#ffffff', // White text for visibility
  }}
  contentbg
>
  <div className="grid grid-cols-2  rounded-xl m-2 gap-4 p-4">
    <div>
      <label className="block text-sm font-medium  text-gray-300">First Name</label>
      <Input
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        required
        className="mt-1 bg-gray-800 text-white border border-gray-600"
      />
    </div>

   

    <div>
      <label className="block text-sm font-medium text-gray-300">Email</label>
      <Input
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="mt-1 bg-gray-800 text-white border border-gray-600"
      />
    </div>

    <div>
      <label className="block text-sm font-medium text-gray-300">Phone</label>
      <Input
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        className="mt-1 bg-gray-800 text-white border border-gray-600"
      />
    </div>

    <div>
      <label className="block text-sm font-medium text-gray-300">Batch ID</label>
      <Input
        value={batchId}
        onChange={(e) => setBatchId(e.target.value)}
        className="mt-1 bg-gray-800 text-white border border-gray-600"
      />
    </div>

    <div>
      <label className="block text-sm font-medium text-gray-300">Gender</label>
      <Input
        value={gender}
        onChange={(e) => setGender(e.target.value)}
        className="mt-1 bg-gray-800 text-white border border-gray-600"
      />
    </div>

    <div>
      <label className="block text-sm font-medium text-gray-300">Present Address</label>
      <Input
        value={presentAddress}
        onChange={(e) => setPresentAddress(e.target.value)}
        className="mt-1 bg-gray-800 text-white border border-gray-600"
      />
    </div>

    <div>
      <label className="block text-sm font-medium text-gray-300">Permanent Address</label>
      <Input
        value={permanentAddress}
        onChange={(e) => setPermanentAddress(e.target.value)}
        className="mt-1 bg-gray-800 text-white border border-gray-600"
      />
    </div>

    <div>
      <label className="block text-sm font-medium text-gray-300">Guardian Phone Number</label>
      <Input
        value={guardianPhoneNumber}
        onChange={(e) => setGuardianPhoneNumber(e.target.value)}
        className="mt-1 bg-gray-800 text-white border border-gray-600"
      />
    </div>

    <div>
      <label className="block text-sm font-medium text-gray-300">Relation with Guardian</label>
      <Input
        value={relationWithGuardian}
        onChange={(e) => setRelationWithGuardian(e.target.value)}
        className="mt-1 bg-gray-800 text-white border border-gray-600"
      />
    </div>

    <div>
      <label className="block text-sm font-medium text-gray-300">Discount</label>
      <Input
        type="number"
        value={discount}
        onChange={(e) => setDiscount(e.target.value)}
        className="mt-1 bg-gray-800 text-white border border-gray-600"
      />
    </div>
    <div>
      <label className="block text-sm font-medium text-gray-300">Additional Note</label>
      <Input.TextArea
        value={additionalNote}
        onChange={(e) => setAdditionalNote(e.target.value)}
        className="mt-1 bg-gray-800 text-white border border-gray-600"
      />
    </div>


    <div className="col-span-2">
      <label className="block text-sm font-medium text-gray-300">Admission Date</label>
      <DatePicker
        value={admissionDate}
        onChange={(date) => setAdmissionDate(date)}
        className="mt-1 bg-gray-800 text-white border border-gray-600"
      />
    </div>
  </div>
</Modal>
</ConfigProvider>
     

    </div>
  );
};

export default Students;
