import { useEffect, useState } from "react"
import { Card, Divider, message, Modal, Select } from 'antd'
import { studentAdmission } from "../../../Helpers/requests/student"
import './admission.css'
import image from './happy.png'
import { createUserWithEmailAndPassword } from "firebase/auth"
import { getAllBatchesWithStudents } from "../../../Helpers/requests/batch"
const AdmissionPage = ({modal}) => {
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [phone, setPhone] = useState("88")
    const [email, setEmail] = useState("")
    const [id, setStudentID] = useState("")
    const [batch, setBatch] = useState("")
    const [guardianPhone, setGuardianPhone] = useState("")
    const [gender, setGender] = useState("")
    const [preAdd, setpreAdd] = useState("")
    const [perAdd, setperAdd] = useState("")
    const [notes, setNotes] = useState("")
    const [discount, setDiscount] = useState("")
   const [result, setResult] = useState("")
   const [modalState, setModalState] = useState(false)
  const [batchesItemData, setBatchesItemData] = useState([""])
const [ setted, setSetted ] = useState(false)

   const [relationWithGuardian, setRelationWithGuardian] = useState("")
   const [resultColor, setResultColor] = useState("")
   const Option = Select


   useEffect(() => {
    let g = []
    getAllBatchesWithStudents()
        .then(batches => {
           batches.forEach((e)=>{
            if(e.active === true){
              g.push(e.name)
            }
           })
           setBatchesItemData(g)
        })
        .catch(() => {
            message.error("Failed to load batches");
        });
}, []);
    return(<>
   {
    setted ===true ? <>
   <div className="text-white w-full h-full flex flex-col items-center justify-center">
  <div className="relative w-3/4 h-3/4 flex items-center justify-center animate-pop">
    <img 
      src={image} 
      alt="Student Admitted" 
      className="w-[25vw] m-10 h-[25vh] object-contain rounded pop-effect"
    />
  </div>
  <Divider />
  <center>
    <h1 className="mx-auto text-2xl font-bold">Student Admitted!</h1>
  </center>
  <Divider />
  <div className="mt-4">
    <button 
      className="px-3 py-2 bg-blue-400 text-white m-3 rounded transition-colors duration-200 hover:bg-blue-500" 
      onClick={(e) => {
        setSetted(false);
        setFirstName("");
        setLastName("");
        setPhone("88");
        setEmail("");
        setperAdd("");
        setpreAdd("");
        setRelationWithGuardian("");
        setBatch("");
        setGuardianPhone("");
        setNotes("");
        setDiscount("");
        setResult("");
      }}
    >
      Add Another
    </button>
    <button 
      className="px-3 py-2 bg-white text-black m-3 rounded transition-colors duration-200 hover:bg-gray-200" 
      onClick={(e) => {
        setSetted(false);
        modal(false);
      }}
    >
      Done
    </button>
  </div>
</div>

    </> :  <section>
    <center>
        <div className="w-full  mt-0">
               
           <div className="">
              <div className="w-full"> <>

              <section className="w-full max-w-4xl mx-auto bg-[#343A40] shadow-xl rounded-xl p-10">
<div className="text-left mb-8">
<h2 className="text-4xl w-full font-light text-white">
  Student
</h2>
<h2 className="text-2xl w-full font-light text-white">
   Information
</h2>
</div>
<form
onSubmit={(e) => {
  e.preventDefault();
  studentAdmission(firstName, lastName, batch, email, phone, guardianPhone, notes, discount, gender, preAdd, perAdd, relationWithGuardian)
    .then((e) => {
      message.success("Admission Success")
     setSetted(true)
    //  modal(false)
    })
    .catch(() => message.error("Failed to admit the student"));
}}
>
<div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
  {/* First Column */}
  <div className="space-y-6">
      <input
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        type="text"
        required
        placeholder="Full Name"
        className="w-full text-gray-700 focus:outline-none focus:ring-2 px-3  rounded-xl py-3 bg-gray-50 focus:ring-indigo-500 "
      />

    <div className="flex items-center border-2 rounded-xl px-2 py-2 bg-gray-50">
      Gender: 
      <Select
        className="w-full ml-1 text-gray-700"
        onSelect={(e) => setGender(e)}
        defaultActiveFirstOption
      >
        <Option value="male">Male</Option>
        <Option value="female">Female</Option>
      </Select>
    </div>

    <div className="flex items-center border-2 rounded-xl px-2 py-2 bg-gray-50">
      Batch: {" "}
      <Select
        className="w-full ml-1 text-gray-700"
        onSelect={(e) => setBatch(e)}
         >
        {batchesItemData.map((e) => (
          <Option key={e} value={e}>{e}</Option>
        ))}
      </Select>
    </div>


      <input
        value={perAdd}
        onChange={(e) => setperAdd(e.target.value)}
        type="text"
        
        placeholder="Permanent Address"
        className="w-full text-gray-700 focus:outline-none focus:ring-2 px-3  rounded-xl py-3 bg-gray-50 focus:ring-indigo-500 "
        />
      

     <input
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        type="tel"
        required
        placeholder="Phone Number"
        className="w-full text-gray-700 focus:outline-none focus:ring-2 px-3  rounded-xl py-3 bg-gray-50 focus:ring-indigo-500 "
        />
   

      <input
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        type="email"
        required
        placeholder="Email Address"
        className="w-full text-gray-700 focus:outline-none focus:ring-2 px-3  rounded-xl py-3 bg-gray-50 focus:ring-indigo-500 "
        />
  </div>

  {/* Second Column */}
  <div className="space-y-6">
    <input
        value={preAdd}
        onChange={(e) => setpreAdd(e.target.value)}
        type="text"
        required
        placeholder="Present Address"
        className="w-full text-gray-700 focus:outline-none focus:ring-2 px-3  rounded-xl py-3 bg-gray-50 focus:ring-indigo-500 "
        />
     <input
        value={guardianPhone}
        onChange={(e) => setGuardianPhone(e.target.value)}
        type="tel"
        required
        placeholder="Guardian Phone"
        className="w-full text-gray-700 focus:outline-none focus:ring-2 px-3  rounded-xl py-3 bg-gray-50 focus:ring-indigo-500 "
        />
   
    <input
        value={discount}
        onChange={(e) => setDiscount(e.target.value)}
        type="number"
        placeholder="Discount"
        className="w-full text-gray-700 focus:outline-none focus:ring-2 px-3  rounded-xl py-3 bg-gray-50 focus:ring-indigo-500 "
        />
   
      <input
        value={relationWithGuardian}
        onChange={(e) => setRelationWithGuardian(e.target.value)}
        type="text"
        placeholder="Relationship with Guardian"
        className="w-full text-gray-700 focus:outline-none focus:ring-2 px-3  rounded-xl py-3 bg-gray-50 focus:ring-indigo-500 "
        />
   
    <input
        value={notes}
        onChange={(e) => setNotes(e.target.value)}
        type="text"
        placeholder="Additional Notes"
        className="w-full text-gray-700 focus:outline-none focus:ring-2 px-3  rounded-xl py-3 bg-gray-50 focus:ring-indigo-500 "
        />
  </div>
</div>

<div className="flex justify-between mt-8">
  <button
    type="reset"
    onClick={() => {
      setFirstName("");
      setLastName("");
      setPhone("88");
      setEmail("");
      setBatch("");
      setGuardianPhone("");
      setNotes("");
      setDiscount("");
      setResult("");
    }}
    className="bg-red-500 text-white rounded-lg py-3 px-6 transition-all hover:bg-red-600"
  >
    Clear
  </button>
  <button
    type="submit"
    disabled={result === "Active"}
    className="bg-green-500 text-white rounded-lg py-3 px-6 transition-all hover:bg-green-600"
  >
    Submit
  </button>
</div>

{result && (
  <p className={`mt-4 text-${resultColor}-700`}>{result}</p>
)}
</form>
</section>


</>
</div>
              <div>





              </div>
           </div>
        </div>
    </center>
</section>
   }
    </>)

}

export default AdmissionPage