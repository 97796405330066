import { Button, Divider, Select, Input, Space, Badge, Descriptions, message, Modal, Form, InputNumber, DatePicker } from 'antd';
import { useState, useRef } from 'react';
import axios from 'axios';
import { sendSMS } from '../../../Helpers/requests/admin';
import avatar01 from './avatar-1.png'
const { Option } = Select;
function convertDate(isoString) {
  const date = new Date(isoString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();   

  return `${day}-${month}-${year}`;   

}
const StudentInfo = ({studentData}) => {
  const [studentCurrentData, setStudentCurrentData] = useState(studentData)
  const [totalPresent, setTotalPresent] = useState(0);
  const [totalLate, setTotalLate] = useState(0);
  const [totalAbsent, setTotalAbsent] = useState(0);
  const [xferConf, setXferConf] = useState(false)
  const handleAddPayment = async (installmentIndex, amount, pid) => {
    // Update local data for a smoother UX
    message.loading("Adding payment")
    const updatedData = { ...studentCurrentData };
    updatedData.payments[installmentIndex].amount += amount;
    let cd = new Date()
    updatedData.payments[installmentIndex].paymentDate = cd;
    setStudentCurrentData(updatedData);
    
    // Send the PUT request
    try {
      await axios.put(`https://api.myaimgenius.com/admin/student/${studentData._id}`, updatedData);
      
      try{
       await axios.put(`https://api.myaimgenius.com/admin/payments/${pid}`, {
          amount: amount,
          date: cd
        })
        message.success('Payment updated successfully');
        sendSMS(studentData.phone, `Dear ${studentData.firstName}, Your payment of BDT${amount} is successfully recieved. \n Payement ID: ${pid}. Grüße, AIM GENIUS`)
      }
      catch (error){
        message.error("An error occured")
      }
    } catch (error) {
      message.error('Error updating payment');
      console.error(error);
    }
  };

  return (
    <div className="p-0">
      <style
           dangerouslySetInnerHTML={{
             __html:
               "\n    :root {\n        --main-color: #4a76a8;\n    }\n\n    .bg-main-color {\n        background-color: var(--main-color);\n    }\n\n    .text-main-color {\n        color: var(--main-color);\n    }\n\n    .border-main-color {\n        border-color: var(--main-color);\n    }\n"
           }}
         />
         <link
           href="https://unpkg.com/tailwindcss@^1.0/dist/tailwind.min.css"
           rel="stylesheet"
         />
         <div className="bg-[#343A40]  h-auto rounded-xl">
           <div className="bg-[#343a40] flex mx-auto mt-10 rounded-xl p-5">
         
      


      {studentData && (
   
   <div className="flex w-full md:-mx-2">
        {/* Left Side */}
        <div className="w-1/5 md:mx-2">
          {/* Profile Card */}
          <div className="bg-[#343A40]  w-full p-3 ">
            <img src={avatar01} className='h-[20vh] bg-white p-3 rounded-xl mb-4 w-auto' />
            <h1 className="text-white font-bold text-2xl leading-8 my-1">
              {studentData?.firstName} {studentData?.lastName}
            </h1>
            <h3 className="text-gray-100 font-lg text-semibold leading-6">
              Student of {studentData?.batchId}
            </h3>
            <p className="text-sm text-gray-300 hover:text-gray-600 leading-6">
              {studentData?.additionalNote || "No additional notes"}
            </p>
            <ul className="w-auto text-gray-600 hover:text-gray-700  mt-3 divide-y  ">
              <li className="flex items-center py-3">
                <span>Status</span>
                <span className="ml-6">
                  <span className="bg-green-500 py-1 px-2 rounded text-white text-sm">
                    {studentData?.status || 'Running'}
                  </span>
                </span>
              </li>
              <li className="flex items-center py-3">
                <span>Admission Date</span>
                <span className="ml-6">{convertDate(studentData?.admissionDate) || ''}</span>
              </li>
            </ul>

            <br></br>
            <Divider></Divider>
          </div>
          {/* End of profile card */}
        </div>
        {/* Right Side with Tabs */}
        <div className="w-5/6 mx-2 min-h-[70vh]">
          <div className=" bg-[#343A40] p-3 ">
            
            <div className=" bg-gray-800 rounded-xl shadow-md p-6">
  <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3 text-white">
    {[
      { label: 'ID Number', value: studentData?.studentID || '', icon: '📇' },
      { label: 'Gender', value: studentData?.gender || '', icon: '⚧' },
      { label: 'Contact No.', value: studentData?.phone, icon: '📞', link: `tel:+${studentData?.phone}`, isLink: true },
      { label: 'Email', value: studentData?.email, icon: '✉️', link: `mailto:${studentData?.email}`, isLink: true },
      { label: 'Present Address', value: studentData?.presentAddress || '', icon: '🏠' },
      { label: 'Permanent Address', value: studentData?.permanentAddress || '', icon: '📍' }
    ].map((field, index) => (
      <div
        key={index}
        className="flex items-center bg-gray-900 p-4 rounded-lg shadow-sm hover:shadow-md hover:bg-gray-700 transition-all w-full"
      >
        <div className="flex-shrink-0 text-2xl mr-4">{field.icon}</div>
        <div className="flex-grow">
          <p className="font-semibold text-sm text-gray-400">{field.label}</p>
          <p className="text-base text-gray-100 ">
            {field.isLink ? (
              <a href={field.link} className=" text-amber-400 hover:underline">
                {field.value}
              </a>
            ) : (
              field.value
            )}
          </p>
        </div>
      </div>
    ))}
  </div>
</div>

            <div className="tab-content mt-4">
                {/* Attendance Statistics */}
                <div className="grid grid-cols-4 gap-4 mb-4">
                  <div className=" bg-gray-800  p-3 shadow-lg rounded-lg">
                    <div className="text-green-500 text-2xl font-bold">
                      {totalPresent}
                    </div>
                    <div className="text-gray-600">Total Present</div>
                  </div>
                  <div className=" bg-gray-800  p-3 shadow-lg rounded-lg">
                    <div className="text-orange-500 text-2xl font-bold">
                      {totalLate}
                    </div>
                    <div className="text-gray-600">Total Late</div>
                  </div>
                  <div className=" bg-gray-800  p-3 shadow-lg rounded-lg">
                    <div className="text-red-500 text-2xl font-bold">
                      {totalAbsent}
                    </div>
                    <div className="text-gray-600">Total Absent</div>
                  </div>
                  <div className=" bg-gray-800  cursor-pointer p-3 shadow-lg rounded-lg">
                    <div className="text-white text-2xl font-bold">
                    View 
                    </div>
                    <div className="text-gray-600">Total Attendance</div>
                  </div>
                </div>
                {/* Attendance Details */}
                <ul className="list-inside space-y-2">
                  {studentData?.attendances?.map((attendance, index) => (
                    <li key={index}>
                      <div className={`text-sm ${attendance.status === 'Present' ? 'text-green-500' : attendance.status === 'Late' ? 'text-orange-500' : 'text-red-500'}`}>
                        {convertDate(attendance.date)} - {attendance.status}
                      </div>
                    </li>
                  )) || <li>No Attendances</li>}
                </ul>
              </div>
              <div className="tab-content">
                <div className=" text-sm">
                  <div>
                    <div className=" items-center space-x-2 font-semibold text-white leading-8 mb-3">
                     
  <h3 className="text-lg font-bold mb-4">Payment Details</h3>
                    </div>
                    <div className="grid gap-4 grid-cols-2 sm:grid-cols-4 lg:grid-cols-4">
  {studentData?.payments?.length > 0 ? (
    studentData.payments.map((payment, index) => (
      <div
        key={index}
        className={`relative overflow-hidden transition-transform transform  bg-gray-800 p-6 rounded-2xl shadow-lg ${
          payment.amount === 0
            ? 'border-red-500'
            : payment.amount === payment.expectedAmount
            ? 'border-teal-500'
            : 'border-yellow-500'
        } border-t-4`}
      >
        {/* Installment Badge */}
        <span className="absolute top-4 right-4 text-xs px-3 py-1 rounded-full font-semibold bg-gray-200 text-gray-700">
          Installment {payment.installmentNumber}
        </span>

        {/* Amount Display */}
        <p
          className={`text-2xl font-bold mt-4 mb-2 ${
            payment.amount === 0
              ? 'text-red-500'
              : payment.amount === payment.expectedAmount
              ? 'text-teal-500'
              : 'text-yellow-500'
          }`}
        >
          ৳{payment.amount === payment.expectedAmount
            ? payment.amount
            : `${payment.amount} / ${payment.expectedAmount}`}
        </p>

        {/* Payment Date */}
        <p className="text-gray-500 text-sm mb-6">
          {convertDate(payment.paymentDate) === 'NaN-NaN-NaN'
            ? 'Date not available'
            : convertDate(payment.paymentDate)}
        </p>

        {/* Payment Input Form */}
        {payment.amount !== payment.expectedAmount && (
          <form
            onSubmit={(e) => e.preventDefault()}
            className="flex items-center space-x-2"
          >
            <InputNumber
              max={payment.expectedAmount - payment.amount}
              className="w-full rounded-lg border-gray-300 shadow-sm focus:border-teal-500 focus:ring focus:ring-teal-200 transition"
              placeholder="Enter payment"
              onPressEnter={(e) => {
                if (xferConf === false) {
                  setXferConf(true);
                  setTimeout(() => setXferConf(false), 5000);
                } else {
                  const amount = parseFloat(e.target.value);
                  if (!isNaN(amount)) {
                    handleAddPayment(index, amount, payment.payment);
                    e.target.value = '';
                  } else {
                    message.error('Invalid amount');
                  }
                }
              }}
            />
            <button
              type="submit"
              className="px-4 py-2 rounded-lg bg-teal-500 text-white hover:bg-teal-600 transition"
            >
              Add
            </button>
          </form>
        )}

      </div>
    ))
  ) : (
    <p className="text-gray-500 col-span-full text-center">No Payments</p>
  )}
</div>



                  </div>
                </div>
              </div>
            

          

            <br></br>
            <Divider></Divider>
          </div>
        </div>
      </div>
          
           )}




           </div>
         </div>
   
  
    </div>
  );
};

export default StudentInfo;



