import { root } from ".."
import Router from "../Helpers/router"

const NotFound = () => {
return(<>

    <center>
        <div>
            <h1 className="font-black text-red-700 text-4xl mt-24">
                404
            </h1>
            <p>The page you were looking for, was not found</p>
            <button
             onClick={(e)=>{Router(root, ["home"])}}
              className="mt-8 p-2 font-bold text-gray-700 bg-[#ffcb05] rounded-xl">
                Return Home
            </button>
        </div>
    </center>

</>)
}

export default NotFound