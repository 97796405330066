  import React, { useState, useEffect } from 'react';
  import { Row, Col, Form, Input, Button, List, Statistic, Progress, Card, Popconfirm, message } from 'antd';
  import { Bar, Pie } from 'react-chartjs-2';
  import axios from 'axios';
  import { addExpense, addIncome,  getAllExpenses } from '../../../Helpers/requests/expense';

  const Expenses = () => {
    const [form] = Form.useForm();
    const [formInc] = Form.useForm();
    const [expenses, setExpenses] = useState([]);
    const [incomes, setIncomes] = useState([]); // Example total income value
    const [monthlyIncome, setMonthlyIncome] = useState(0); // Example total income value
    const [searchQuery, setSearchQuery] = useState(''); // State for search input




    // Fetch expenses on component load
    useEffect(() => {
      fetchExpenses();
      getThisMonthIncomes()
    }, []);

    // Function to fetch expenses from the backend
    const fetchExpenses = async () => {

      getAllExpenses().then((e)=>{
        setExpenses(e);
      }).catch((e)=>{
        message.error('Failed to fetch expenses');

      })
    };

    // Submit handler for adding an expense
    const handleAddExpense = async (values) => {
      addExpense(values).then((e)=>{
        message.success('Expense added successfully');
        fetchExpenses()
        form.resetFields();
      }).catch((e)=>{

        message.error('Failed to add expense');
      })
    
    };
    // Submit handler for adding an expense
    const handleAddIncome = async (values) => {
      addIncome({...values, expectedAmount: values.amount}).then((e)=>{
        message.success('Income added successfully');
        fetchExpenses()
        formInc.resetFields();
        getThisMonthIncomes()
      }).catch((e)=>{

        message.error('Failed to add income');
      })
    
    };




    const getThisMonthIncomes = async () => {
      try {
        const response = await axios.get("https://api.myaimgenius.com/admin/incomes");
        const incomes = response.data;
   
        const today = new Date();
        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth()   
       + 1, 0);
        let income = 0
     incomes.forEach((e)=>{
        income += e.amount
    })
        const thisMonthIncomes = incomes.filter(income => {
          const incomeDate = new Date(income.date);
          return incomeDate >= firstDayOfMonth && incomeDate <= lastDayOfMonth;
        });
    
        let totalThisMonthIncome = 0;
        thisMonthIncomes.forEach(income => {
          totalThisMonthIncome += income.amount;
        });
         setIncomes(income)
         setMonthlyIncome( totalThisMonthIncome) // Return the total income for this month
      } catch (error) {
        console.error("Error fetching or filtering incomes:", error);
        // Handle errors appropriately, e.g., display an error message to the user
      }
    };

    


    // Handle search query change
    const handleSearchChange = (e) => {
      setSearchQuery(e.target.value.toLowerCase());
    };

    // Filter expenses based on search query
    const filteredExpenses = expenses.filter((expense) =>
      expense.title.toLowerCase().includes(searchQuery) ||
      expense.category.toLowerCase().includes(searchQuery) ||
      (expense.description && expense.description.toLowerCase().includes(searchQuery)) ||
      (expense.transactionID && expense.transactionID.toLowerCase().includes(searchQuery))
    );

    // Calculate total expense
    const totalExpense = expenses.reduce((acc, expense) => acc + parseFloat(expense.amount), 0);
    const remainingBalance = incomes - totalExpense;

    // Calculate monthly expenses and income
    const currentMonth = new Date().toLocaleString('default', { month: 'short' });
    const monthlyExpenses = expenses.reduce((acc, expense) => {
      const expenseMonth = new Date(expense.date).toLocaleString('default', { month: 'short' });
      return expenseMonth === currentMonth ? acc + parseFloat(expense.amount) : acc;
    }, 0);

    // Data for categorized expenses (this month)
    const categoryData = expenses.reduce((acc, expense) => {
      const expenseMonth = new Date(expense.date).toLocaleString('default', { month: 'short' });
      if (expenseMonth === currentMonth) {
        acc[expense.category] = acc[expense.category] || 0;
        acc[expense.category] += parseFloat(expense.amount);
      }
      return acc;
    }, {});


    // Data for monthly expenses chart
    const monthlyData = expenses.reduce((acc, expense) => {
      const month = new Date(expense.date).toLocaleString('default', { month: 'short' });
      acc[month] = acc[month] || 0;
      acc[month] += parseFloat(expense.amount);
      return acc;
    }, {});

    return (
      <div className='p-4 mt-4 w-full rounded-xl bg-[#343a40]'>
        <Row gutter={[16, 16]}>
          {/* Input Form */}
          <Col span={6}>
          <Card title="Debit" className=' drop-shadow-xl shadow-gray-700'>
              <Form form={form} onFinish={handleAddExpense} layout="vertical">
                <Form.Item name="category" label="Expense Category" rules={[{ required: true, message: 'Please enter the category' }]}>
                  <Input placeholder="E.g., Food, Transportation" />
                </Form.Item>
                <Form.Item name="amount" label="Amount" rules={[{ required: true, message: 'Please enter the amount' }]}>
                  <Input type="number" placeholder="Amount" />
                </Form.Item>
                <Form.Item name="title" label="Title" rules={[{ required: true, message: 'Please enter a title' }]}>
                  <Input placeholder="Expense title" />
                </Form.Item>
                <Form.Item name="description" label="Description">
                  <Input.TextArea placeholder="Description" />
                </Form.Item>
                <Form.Item name="transactionID" label="Transaction ID">
                  <Input placeholder="Transaction ID" />
                </Form.Item>
                <Form.Item>
                  <Popconfirm
                    title="Are you sure? This action is not reversible."
                    onConfirm={form.submit}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button type="primary">Add Expense</Button>
                  </Popconfirm>
                </Form.Item>
              </Form>
            </Card>
            <Card title="Credit" className='mt-4 drop-shadow-xl shadow-gray-700'>
              <Form form={formInc} onFinish={handleAddIncome} layout="vertical">
                <Form.Item name="category" label="Income Category" rules={[{ required: true, message: 'Please enter the category' }]}>
                  <Input placeholder="E.g., Food, Transportation" />
                </Form.Item>
                <Form.Item name="amount" label="Amount" rules={[{ required: true, message: 'Please enter the amount' }]}>
                  <Input type="number" placeholder="Amount" />
                </Form.Item>
                <Form.Item name="title" label="Title" rules={[{ required: true, message: 'Please enter a title' }]}>
                  <Input placeholder="Income title" />
                </Form.Item>
                <Form.Item>
                  <Popconfirm
                    title="Are you sure? This action is not reversible."
                    onConfirm={formInc.submit}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button type="primary">Add Income</Button>
                  </Popconfirm>
                </Form.Item>
              </Form>
            </Card>
          </Col>

          {/* Statistics */}
          <Col span={6}>
            <Card title="Statistics" className=' drop-shadow-xl shadow-gray-700'>
              <Statistic title="Total Income" value={incomes} valueStyle={{ color: '#3f8600' }} prefix="৳" />
              <Statistic  title="Total Expenses" value={totalExpense}  valueStyle={{ color: '#cf1322' }} prefix="৳" style={{ margin: '16px 0' }} />
              <Progress percent={((totalExpense / incomes) * 100).toFixed(2)} />
              <Statistic title="Available Balance" value={remainingBalance} prefix="৳" />
            </Card>
            <br />
            <Card title="Monthly Expenses (Chart)">
              <Bar
                data={{
                  labels: Object.keys(monthlyData),
                  datasets: [
                    {
                      label: 'Monthly Expenses',
                      data: Object.values(monthlyData),
                      backgroundColor: '#36A2EB',
                    },
                  ],
                }}
              />
            </Card>
          </Col>

          {/* Categorized Expenses (This Month) */}
          <Col span={6}>
            <Card title="Statistics (This Month)">
              <Statistic title="Income" value={monthlyIncome}  valueStyle={{ color: '#3f8600' }}  prefix="৳" />
              <Statistic title="Expenses" value={monthlyExpenses}  valueStyle={{ color: '#cf1322' }} prefix="৳" style={{ margin: '16px 0' }} />
            </Card>
            <br />
            <Card title="Categorized Expenses (This Month)">
              <List
                dataSource={Object.keys(categoryData)}
                renderItem={(category) => (
                  <List.Item>
                    <List.Item.Meta
                      title={category}
                      description={`Total: ৳${categoryData[category]}`}
                    />
                  </List.Item>
                )}
              />
            </Card>
          </Col>

          <Col span={6}>
            <Card title="Expenses List">
              <Input
                placeholder="Search expenses..."
                value={searchQuery}
                onChange={handleSearchChange}
                style={{ marginBottom: 16 }}
              />
              <List
                dataSource={filteredExpenses}
                pagination={{
                  onChange: (page) => {
                    console.log(page);
                  },
                  pageSize: 3,
                }}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      title={`${item.title} - ৳${item.amount}`}
                      description={`${item.category} | ${item.description || ''} | Transaction ID: ${item.transactionID || 'N/A'}`}
                    />
                  </List.Item>
                )}
              />
            </Card>
            <br />
            <Card title="Categorized Expenses (Chart)">
              <Pie
                data={{
                  labels: Object.keys(categoryData),
                  datasets: [
                    {
                      data: Object.values(categoryData),
                      backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#8BC34A', '#FF9800'],
                    },
                  ],
                }}
              />
            </Card>
          </Col>
        </Row>
      </div>
    );
  };

  export default Expenses;
