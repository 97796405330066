import { message } from "antd";
import { initializeApp } from "firebase/app";
import {collection, deleteDoc, doc, getDoc, getDocs, getFirestore, setDoc} from 'firebase/firestore'
import { getAuth, isSignInWithEmailLink, onAuthStateChanged, sendSignInLinkToEmail, signInWithEmailAndPassword, signInWithEmailLink, signOut } from 'firebase/auth'
import Router from "./router";
import { root } from "..";
const firebaseConfig = {
  apiKey: "AIzaSyBX-T6US5-bwZgPLIZbsYJYDnoXFSKujBk",
  authDomain: "aim-genius.firebaseapp.com",
  projectId: "aim-genius",
  storageBucket: "aim-genius.appspot.com",
  messagingSenderId: "381285941734",
  appId: "1:381285941734:web:88876ab729ae24caefd627"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app)
export const auth = getAuth(app)

export const StudentLogin = (email) => {
    return new Promise((en, ek)=>{
        sendSignInLinkToEmail(auth, email)
        // signInWithEmailLink(auth, email, "http://localhost:3000/auth_")
        .then((result) => {
          en(result)
        })
        .catch((error) => {
          console.log(error)
        });
    })
}

export const TeacherAndAdminLogin = (email, password) => {
    return new Promise((en, ek)=>{
        signInWithEmailAndPassword(auth, email, password).then((e)=>{
            en(e.user)
        }).catch((e)=>{
            ek(e)
        })
    })
}

export const logout = () =>{ 
    signOut(auth).then((e)=>{
        window.location = "./"
    }).catch((e)=>{
        message.error("Failed to logout")
    })
} 
export const checkAuth = () => {
    return new Promise((en, ek)=>{
        onAuthStateChanged(auth, (e)=>{
            if(e){
                en(e)
            }else{
                ek(null)
            }
        })
    }) 
}


export const setRegistration = (data) => {
        return new Promise ((res, rej)=>{
            
        })
}


export const fetchTodo = () => {
    return new Promise ((res, rej)=>{
        getDoc( doc(db, "AppData", "portal")).then((e)=>{
            res(e.data().tasks)
        }).catch((e)=>{
            rej(e)
            message.error("Error Fetching To-Do List")
        })
    })
}

export const updateTodo = (todo) => {
    return new Promise ((res, rej)=>{
        setDoc( doc(db, "AppData", "portal"), {tasks: todo}).then((e)=>{
            Router(root, ["admin"])
        }).catch((e)=>{
            message.error("Error Fetching To-Do List")
        })
    })
}

export const getLeaderboard = async () => {
    try {
      const leaderboardDoc = doc(db, "AppData", "leaderboard");
      const docSnapshot = await getDoc(leaderboardDoc);
  
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        return data || [];
      } else {
        console.error("No leaderboard data found.");
        return [];
      }
    } catch (error) {
      console.error("Error fetching leaderboard:", error);
      throw error;
    }
  };


export const updateLeaderboard = async (newOrder) => {
  try {
    const leaderboardDoc = doc(db, "AppData", "leaderboard");
    await setDoc(leaderboardDoc, {newOrder}, {merge: true});
  } catch (error) {
    console.error("Error updating leaderboard:", error);
    throw error;
  }
};

export async function deleteStudentRegistration(docId) {
    try {
      await deleteDoc(doc(db, "StudentRegistration", docId));
      console.log("Document successfully deleted!");
      // Update your local registrations array if needed (consider efficiency)
      // registrations.splice(k, 1); // Assuming registrations contains objects with an 'id' property
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  }
export const getRegistrations =  () => {

    return new Promise ((res, rej)=>{
        const registrations = collection(db, "StudentRegistration")
        getDocs(registrations).then((e)=>{
            res(e.docs)
        }).catch((e)=>{
            rej("Error")
        })
    })
        

    
 }

export const selfStudentRegister = (data) => {

    

}