import React, { useState, useEffect } from "react";
import { FaTrash } from "react-icons/fa";
import { fetchTodo, updateTodo } from "../../../Helpers/firebase";

const TodoApp = ({refresh, dom}) => {
  const [task, setTask] = useState("");
  const [todos, setTodos] = useState([]);

  // Fetch initial todos
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchTodo();
        setTodos(data || []); // Initialize todos with fetched data or empty array
      } catch (error) {
        console.error("Error fetching todos:", error);
      }
    };
    fetchData();
  }, []);
  const updateData = async () => {
    try {
      await updateTodo(todos);
    } catch (error) {
      console.error("Error updating todos:", error);
    }
  };
  
  const addTask = () => {
    const d = new Date();
    const prevTodos = todos
    prevTodos.push({
      title: task,
      date: `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}`,
      done: false,
    })
    if (task) {
      setTodos(
        prevTodos
      );
      console.log(todos)
      updateData(todos);
      setTask("");
    }
  };

  const removeTask = (index) => {
    todos.splice(index, 1)
    
    setTodos(todos);
    updateData(todos).then((e)=>{
      fetchTodo().then((e)=>{
setTodos(todos)     
 })
    });
  };

  const toggleDone = (index) => {
   const todoUp = todos
    todoUp[index].done  = true
    setTodos(todoUp)
    updateTodo(todoUp).then((e)=>{
      setTodos(todoUp)
    })
      
  };

  const sortTodos = (todos) => {
    return todos.sort((a, b) => {
      if (a.done === b.done) {
        updateData(todos);
        return new Date(b.date) - new Date(a.date);
      }
      updateData(todos);
      return a.done - b.done;
    });
  };

  return (
    <div className="relative flex flex-col w-full rounded-xl bg-white bg-clip-border shadow-3xl shadow-gray-400 p-4">
      <div className="flex justify-between items-center mb-4">
        <h4 className="text-xl font-bold text-navy-700">TO/DO List</h4>
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          addTask();
        }}
      >
        <div className="flex mb-4">
          <input
            type="text"
            value={task}
            onChange={(e) => setTask(e.target.value)}
            className="w-full p-4 bg-gray-100 text-navy-700 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Enter a new task"
          />
          <button
            type="submit"
            className="ml-2 px-4 py-2 bg-white text-[#343a40] rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Add
          </button>
        </div>
      </form>

      <div className="h-full w-full">
        {todos.map((todo, index) => (
          <div
            key={index}
            className="mt-2 flex items-center justify-between px-6 py-4 bg-gray-100 rounded-md shadow-md"
          >
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={todo.done}
                onChange={() => toggleDone(index)}
                className="relative h-[20px] w-[20px] appearance-none items-center 
                  justify-center rounded-md border border-gray-300 text-white/0 
                  outline-none transition duration-[0.2s] checked:border-none 
                  checked:bg-blue-500 hover:cursor-pointer"
                name={`task-${index}`}
              />
              <div>
                <p
                  className={`text-lg font-bold text-navy-700 ${
                    todo.done ? "line-through text-gray-500" : ""
                  }`}
                >
                  {todo.title}
                </p>
                <p className="text-sm font-light text-navy-700">{todo.date}</p>
              </div>
            </div>
            <button
              onClick={() => removeTask(index)}
              className="text-red-500 hover:text-red-600 focus:outline-none"
            >
              <FaTrash />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TodoApp;
