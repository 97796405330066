import { Divider, message, Modal, Popconfirm, Select } from "antd"
import { useEffect, useState } from "react"
import { db, deleteStudentRegistration, getRegistrations } from "../../../Helpers/firebase"
import { getAllBatchesWithStudents } from "../../../Helpers/requests/batch"
import { studentAdmission } from "../../../Helpers/requests/student"
import { FaTrash } from "react-icons/fa"
import { deleteDoc, doc } from "firebase/firestore"

const Registrations = () => {
    const [open, setOpen] = useState(false)
    const [registrations, setRegistrations] = useState([])
    const [batchesItemData, setBatchesItemData] = useState([""])
    const [batch, setBatch] = useState("")
    const Option = Select

    const [currentStudent, setCurrentStudent] = useState({})
    const [fetched, setFetched] = useState(false)

    useEffect((e)=>{
      let g = []

      if(fetched === false){
        getRegistrations().then((e)=>{
          setRegistrations(e)
          setFetched(true)
      }).catch((e)=>{
        message.error("Error fetching Registrations")
      })
      }

      getAllBatchesWithStudents()
      .then(batches => {
         batches.forEach((e)=>{
          if(e.active === true){
            g.push(e.name)
          }
         })
         setBatchesItemData(g)
      })
      .catch(() => {
          message.error("Failed to load batches");
      });


    })

    return(<>
    
    <div className="p-4">

    <div className="min-h-screen bg-gray-50 rounded-xl pb-10">
  {/* Navigation */}
  <div className="mx-auto">

    <main className="">
      <div className="bg-white px-4">
        <div>
          <h2 className="text-2xl font-semibold text-gray-900">
            Student Registrations
          </h2>
          <div className="flex items-center justify-between">
            <div className="my-6 mr-4 flex w-full items-center justify-between rounded-lg border px-3 py-3 sm:w-[350px] sm:flex-initial">
              <input
                className="w-full text-sm outline-none"
                type="text"
                placeholder="Search"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-5 w-6 text-gray-400"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                />
              </svg>
            </div>
            <div>
          
            </div>
          </div>
        </div>
      </div>
     
      <div className="px-4">
        <div className="my-5">
          <h2 className="text-lg font-semibold text-gray-900">Pending Registrations</h2>
          <h2 className="text-md font-semibold text-gray-900">Selected: {currentStudent.firstName}</h2>
        </div>
        <div className="grid sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-3">
          {registrations.map((e, k)=>{
            return(
              <div className="mb-6 rounded-xl shadow-lg bg-gradient-to-r from-white to-blue-50 p-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-4">
                   {/* Icon or avatar placeholder */}
                  <div className="bg-indigo-100 text-indigo-500 rounded-full h-10 w-10 flex items-center justify-center">
                    <i className="fas fa-user"></i>
                  </div>
                  <div>
                    <h3 className="text-base font-semibold text-gray-800">
                      {e.data().firstName}
                    </h3>
                    <span className="inline-block text-xs font-medium text-gray-600 bg-indigo-50 px-2 py-1 rounded-full">
                      Course: {e.data().course}
                    </span>
                  </div>
                </div>
             
              </div>
            
              {/* Details */}
              {/* Details */}
<div className="mt-4 space-y-2">
  <div className="flex items-center justify-between text-sm text-gray-700">
    <span className="font-medium text-gray-800">
      <i className="fas fa-phone-alt mr-2 text-indigo-500"></i> Phone:
    </span>
    <span>{e.data().phone}</span>
  </div>
  <div className="flex items-center justify-between text-sm text-gray-700">
    <span className="font-medium text-gray-800">
      <i className="fas fa-envelope mr-2 text-indigo-500"></i> Email:
    </span>
    <span>{e.data().email}</span>
  </div>
  <div className="flex items-center justify-between text-sm text-gray-700">
    <span className="font-medium text-gray-800">
      <i className="fas fa-map-marker-alt mr-2 text-indigo-500"></i> Address:
    </span>
    <span>{e.data().preAdd}</span>
  </div>
  <div className="flex items-center justify-between text-sm text-gray-700">
    <span className="font-medium text-gray-800">
      <i className="fas fa-user-friends mr-2 text-indigo-500"></i> Guardian:
    </span>
    <span>{e.data().guardianPhone}</span>
  </div>
  <div className="flex items-center justify-between text-sm text-gray-700">
    <span className="font-medium text-gray-800">
      <i className="fas fa-wallet mr-2 text-indigo-500"></i> Payment:
    </span>
    <span>{e.data().paymentDetails}</span>
  </div>
</div>

            
              <div className="my-4 border-t"></div>
            
              {/* Form */}
              <form onSubmit={(ek)=>{
                ek.preventDefault()
                studentAdmission(e.data().firstName, e.data().lastName, currentStudent.batchId, e.data().email, e.data().phone, e.data().guardianPhone, currentStudent.additionalNote, e.data().discount, e.data().gender, e.data().perAdd, e.data().relationshipWithGuardian ).then(()=>{
                  deleteStudentRegistration(e.id).then((e)=>{
                  let p = registrations
                  p.splice(k, 1);
                    message.info("Student Admitted")
                    setRegistrations(p)
                                 })
                }).catch((e)=>{
                  console.log(e)
                  message.error("Failed to admit student")
                })
              }}>
                <div className="grid gap-4">
                  <input
                  onChange={(ek)=>{
                    let p = currentStudent
                    p.firstName = e.data().firstName
                    p.paymentAmount = ek.target.value
                    setCurrentStudent(p)
                  }}
                    className="p-3 border rounded-lg focus:ring-2 focus:ring-indigo-300"
                    type="number"
                    placeholder="Paid Amount"
                  />
                  <input
                  onChange={(ek)=>{
                    let p = currentStudent
                    p.firstName = e.data().firstName
                    p.additionalNote = ek.target.value
                    setCurrentStudent(p)
                  }}
                    className="p-3 border rounded-lg focus:ring-2 focus:ring-indigo-300"
                    type="text"

                    placeholder="Additional Notes"
                  />
                  <div>
                    <span className="block text-sm font-medium text-gray-600 mb-2">
                      Batch:
                    </span>
                    <Select
                      size="large"
                      className="w-full text-gray-700 rounded-lg"
                      onSelect={(l) => {setBatch(l)
                        const psG = currentStudent
                        psG.firstName = e.data().firstName
                        psG.batchId = l
                        setCurrentStudent(psG)

                      }}
                    >
                      {batchesItemData.map((batch, k) => (
                        <Option key={batch+k} value={batch}>
                          {batch}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
                <button
                  className="w-full mt-4 py-2 text-white bg-indigo-500 hover:bg-indigo-600 rounded-lg"
                  type="submit"
                >
                  Admit
                </button>
              </form>
              <Divider></Divider>
<div className="flex">

<span className="text-sm uppercase text-gray-400"> Registration ID: {e.id}</span>
             <span className="ml-auto text-red-500 hover:text-red-900 cursor-pointer transaction duration-100 " ><Popconfirm title="Do you want to remove this registration?" onConfirm={(el)=>{

deleteStudentRegistration(e.id).then((e)=>{
  registrations.splice(k, 1);
  message.info("Registration deleted")
               })
               
              }} ><FaTrash /></Popconfirm></span>
</div>

            </div>
            
            )
          })}
         
          
        </div>
      </div>
    </main>
  </div>
</div>

    </div>
    
    </>)
}

export default Registrations