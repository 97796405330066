import { message } from "antd"
import axios from "axios"
export const sendSMS = (reciever, msg) => {
   

message.info("Message not sent")

//     return new Promise((res, rej)=>{
//         axios.post(`https://880sms.com/smsapi?`,
//              {
//     "api_key" : "C2009736670d01c575ee35.30675644",
//     "senderid" : "8809601012272",
//     "type" : "text",
//     "scheduledDateTime" : "",
//     "msg" : msg,
//     "contacts" : reciever
//   }
//         ).then((e)=>{
//             res("Sent!")
//         }).catch((e)=>{
//             console.log(e)
//             rej("Failed!")
//         })

//     })
}