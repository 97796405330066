import React, { useState, useEffect } from 'react';
import { Space, Table, Tag, Button, Modal, Input, Popconfirm, message, ConfigProvider } from 'antd';
import axios from 'axios';

const { Column } = Table;

const Teachers = () => {
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState(null);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [batchId, setBatchId] = useState('');
  const [password, setPassword] = useState(''); // For adding new teacher password

  // Fetch all teachers
  useEffect(() => {
    fetchTeachers();
  }, []);

  const fetchTeachers = async () => {
    setLoading(true);
    axios.get('https://api.myaimgenius.com/teacher/all')
      .then((response) => {
        setTeachers(response.data);
        setLoading(false);
      })
      .catch(() => {
        message.error("Failed to load teachers");
        setLoading(false);
      });
  };

  // Delete a teacher
  const handleDelete = async (id) => {
    axios.delete(`https://api.myaimgenius.com/teacher/${id}`)
      .then(() => {
        message.success("Teacher Deleted");
        fetchTeachers(); // Refresh the list after deletion
      })
      .catch(() => {
        message.error("Failed to delete teacher");
      });
  };

  // Show modal with selected teacher data for editing
  const showModal = (teacher) => {
    setSelectedTeacher(teacher);
    setIsModalVisible(true);
  };

  // Close edit modal
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // Handle teacher edit (update)
  const handleEdit = async () => {
    const updatedTeacher = {
      name: `${firstName} ${lastName}`,
      email,
      batches: batchId.split(',').map(batch => batch.trim()) // Convert comma-separated batch string to array
    };

    axios.put(`https://api.myaimgenius.com/teacher/${selectedTeacher._id}`, updatedTeacher)
      .then(() => {
        message.success('Teacher updated successfully');
        setIsModalVisible(false);
        fetchTeachers(); // Refresh the list after updating
      })
      .catch(() => {
        message.error('Failed to update teacher');
      });
  };

  // Set form fields when a teacher is selected for editing
  useEffect(() => {
    if (selectedTeacher) {
      const [first, last] = selectedTeacher.name.split(' ');
      setFirstName(first);
      setLastName(last || '');
      setEmail(selectedTeacher.email);
      setBatchId(selectedTeacher.batches.join(', ')); // Convert array to comma-separated string
    }
  }, [selectedTeacher]);

  // Add a new teacher
  const handleAddTeacher = async () => {
    const newTeacher = {
      name: `${firstName} ${lastName}`,
      email,
      password, // New teacher requires a password
    };

    axios.post('https://api.myaimgenius.com/teacher/add', newTeacher)
      .then(() => {
        message.success('Teacher added successfully');
        setIsAddModalVisible(false);
        fetchTeachers(); // Refresh the list after adding a teacher
      })
      .catch((e) => {
        console.log(e)
        message.error('Failed to add teacher');
      });
  };

  // Show add teacher modal
  const showAddModal = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setBatchId('');
    setPassword(''); // Clear all fields
    setIsAddModalVisible(true);
  };

  // Close add teacher modal
  const handleAddCancel = () => {
    setIsAddModalVisible(false);
  };

  return (
    <div className='p-4'>
    

      <div className="relative flex flex-col w-full h-full text-slate-700 bg-white shadow-md rounded-xl bg-clip-border">
  <div className="relative mx-4 mt-4 overflow-hidden text-slate-700 bg-white rounded-none bg-clip-border">
    <div className="flex items-center justify-between">
      <div>
        <h3 className="text-lg font-semibold text-slate-800">Teacher List</h3>
      </div>
      <div className="flex flex-col gap-2 shrink-0 sm:flex-row">
        <Button type="primary" onClick={showAddModal} className="flex items-center gap-2 rounded bg-slate-800 py-2.5 px-4 text-xs font-semibold text-white shadow-md shadow-slate-900/10 transition-all hover:shadow-lg hover:shadow-slate-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none">
          + Add Teacher
        </Button>
      </div>
    </div>
  </div>
  <div className="p-0 overflow-scroll">
    <table className="w-full mt-4 text-left table-auto min-w-max">
      <thead>
        <tr>
          <th className="p-4 transition-colors cursor-pointer border-y border-slate-200 bg-slate-50 hover:bg-slate-100">Name</th>
          <th className="p-4 transition-colors cursor-pointer border-y border-slate-200 bg-slate-50 hover:bg-slate-100">Email</th>
          <th className="p-4 transition-colors cursor-pointer border-y border-slate-200 bg-slate-50 hover:bg-slate-100">ID</th>
          <th className="p-4 transition-colors cursor-pointer border-y border-slate-200 bg-slate-50 hover:bg-slate-100">Batches</th>
          <th className="p-4 transition-colors cursor-pointer border-y border-slate-200 bg-slate-50 hover:bg-slate-100">Actions</th>
        </tr>
      </thead>
      <tbody>
        {teachers.map(record => (
          <tr key={record._id}>
            <td className="p-4 border-b border-slate-200">
              <p className="text-sm font-semibold text-slate-700">{record.name}</p>
            </td>
            <td className="p-4 border-b border-slate-200">
              <p className="text-sm text-slate-500">{record.email}</p>
            </td>
            <td className="p-4 border-b border-slate-200">
              <p className="text-sm text-slate-500">{record._id}</p>
            </td>
            <td className="p-4 border-b border-slate-200">
              <span>
                {record.batches.map(batch => (
                  <Tag key={batch} className="mr-1">{batch.toUpperCase()}</Tag>
                ))}
              </span>
            </td>
            <td className="p-4 border-b border-slate-200">
              <Space size="middle">
                {/* Uncomment if you want to add a view option */}
                {/* <a onClick={() => showModal(record)}>View</a> */}
                <Popconfirm
                  title="Are you sure you want to delete?"
                  onConfirm={() => handleDelete(record._id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <a className="text-red-600">Delete</a>
                </Popconfirm>
              </Space>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  <div className="flex items-center justify-between p-3">
    {/* Add pagination or any other footer content here */}
  </div>
</div>


    
<ConfigProvider
theme={{
  components: {
    Modal: {
      contentBg: "#343a40",
      titleColor:"#fff",
      footerBg: "#343a40",
      headerBg: "#343a40",
    },
  },
}}>
     <Modal
        title="Edit Teacher"
        visible={isModalVisible}
        onOk={handleEdit}
        onCancel={handleCancel}
      >
        <div>
          <label>First Name</label>
          <Input value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
        </div>
        <div>
          <label>Last Name</label>
          <Input value={lastName} onChange={(e) => setLastName(e.target.value)} required />
        </div>
        <div>
          <label>Email</label>
          <Input value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        {/* <div>
          <label>Batches</label>
          <Input value={batchId} onChange={(e) => setBatchId(e.target.value)} />
        </div> */}
      </Modal>

      {/* Modal for Adding a Teacher */}
      <Modal
        title="Add Teacher"
        visible={isAddModalVisible}
        onOk={handleAddTeacher}
        onCancel={handleAddCancel}
      >
        <div className="text-white">
        <div>
          <label>First Name</label>
          <Input value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
        </div>
        <br></br>
        <div>
          <label>Last Name</label>
          <Input value={lastName} onChange={(e) => setLastName(e.target.value)} required />
        </div>
        <br></br>
        <div>
          <label>Email</label>
          <Input value={email} onChange={(e) => setEmail(e.target.value)} required />
        </div>
        <br></br>
        <div>
          <label>Password</label>
          <Input.Password value={password} onChange={(e) => setPassword(e.target.value)} required />
        </div>
        </div>
      </Modal>
  </ConfigProvider>  {/* Modal for Editing a Teacher */}
   
    </div>
  );
};

export default Teachers;
