import axiosBase from "./base"

export const getAllExpenses = () => {
    return new Promise((res, rej)=>{
        axiosBase("get", 'admin/expenses').then((e)=>{
            res(e)
        }).catch((e)=>{
            rej(e)
        })
    })
}

export const addExpense = (expense) => {
    return new Promise((res, rej)=>{
        axiosBase("post", 'admin/expense', expense).then((e)=>{
            res(e)
        }).catch((e)=>{
            rej(e)
        })
    })
}
export const addIncome = (income) => {
    return new Promise((res, rej)=>{
        axiosBase("post", 'admin/income', income).then((e)=>{
            res(e)
        }).catch((e)=>{
            rej(e)
        })
    })
}



