import NotFound from "../Misc/notFound";
import AdminDashbaord from "../Pages/Admin Dashboard";
import LoginPage from "../Pages/Login";
import { checkAuth } from "./firebase";

const emailRegex = /^[a-zA-Z0-9._%+-]+@student\.myaimgenius\.com$/;

const Router = (root, page) => {
    const addToDom = (dom) => {
        window.history.pushState(null, null, page[0])
        root.render(dom)
    }

    switch(page[0].toLowerCase()){
        case "login": 
            addToDom(<LoginPage/>)
        break;
        case "": 
        checkAuth().then((e)=>{
            
                addToDom(<AdminDashbaord/>)
            
        }).catch((e)=>{
            addToDom(<LoginPage/>)
        })
        break;
      
        case "admin":
            checkAuth().then((e)=>{
                addToDom(<AdminDashbaord/>)
            }).catch((e)=>{
                addToDom(<LoginPage/>)
            })
        break;

     
        default:
            addToDom(<NotFound></NotFound>)
        break;
    }
}

export default Router