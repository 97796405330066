import React, { useEffect, useState } from 'react';
import { Card, Col, Divider, Drawer, List, message, Row, Select, Switch, Popconfirm, Input, Button, Modal, ConfigProvider } from 'antd';
import { DollarCircleOutlined, EditOutlined } from '@ant-design/icons';
import { createBatch, deleteBatch, getAllBatchesWithStudents, updateBatch } from '../../../Helpers/requests/batch';
import axios from 'axios';

const { Option } = Select;

const Batches = () => {
    const [newBatchName, setNewBatch] = useState("");
    const [newBatchID, setNewBatchID] = useState("");
    const [newBatchFee, setNewBatchFee] = useState("");
    const [newBatchInstallments, setNewBatchInstallments] = useState("");
    const [loading, setLoading] = useState(true);
    const [batches, setBatches] = useState([]);
    const [drawerActionID, setDrawerActionID] = useState({ id: "", action: "", data: {} });
    const [open, setOpen] = useState(false);
    const [teacherID, setTeacherID] = useState("");
    const [teachersList, setTeachers] = useState([{name: "", _id:""}])
    const [studentModalOpen, setStudentModalOpen] = useState(false);
    const [selectedBatchStudents, setSelectedBatchStudents] = useState([]);

  
  const closeStudentModal = () => {
      setStudentModalOpen(false);
  };

  const showStudentModal = (students) => {
    console.log(students)
    setSelectedBatchStudents(students);
    setStudentModalOpen(true);
};


    const showDrawer = (batch) => {

      setDrawerActionID({ id: batch.id, action: "edit", data: batch });
    
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const removeStudent = (studentId) => {
        const updatedStudents = drawerActionID.data.students.filter(student => student._id !== studentId);
        const updatedBatch = { ...drawerActionID.data, students: updatedStudents };

        updateBatch(drawerActionID.id, updatedBatch)
            .then(() => {
                message.success("Student removed successfully");
                setDrawerActionID({ ...drawerActionID, data: updatedBatch });
                setBatches(batches.map(b => b.id === drawerActionID.id ? updatedBatch : b));
            })
            .catch(() => {
                message.error("Failed to remove student");
            });
    };

    const changeBatchLevel = (value) => {
        const updatedBatch = { ...drawerActionID.data, level: value };

        updateBatch(drawerActionID.id, updatedBatch)
            .then(() => {
                message.success("Batch level updated successfully");
                setDrawerActionID({ ...drawerActionID, data: updatedBatch });
                setBatches(batches.map(b => b.id === drawerActionID.id ? updatedBatch : b));
            })
            .catch(() => {
                message.error("Failed to update batch level");
            });
    };

    const toggleBatchActive = (checked) => {
        const updatedBatch = { ...drawerActionID.data, active: checked };

        updateBatch(drawerActionID.id, updatedBatch)
            .then(() => {
                message.success("Batch status updated successfully");
                setDrawerActionID({ ...drawerActionID, data: updatedBatch });
                setBatches(batches.map(b => b.id === drawerActionID.id ? updatedBatch : b));
            })
            .catch(() => {
                message.error("Failed to update batch status");
            });
    };
   
    const fetchTeachers = async () => {
      setLoading(true);
      axios.get('https://api.myaimgenius.com/teacher/all')
        .then((response) => {
          console.log(response)
          setTeachers(response.data);
        })
        .catch((err) => {
          console.log(err)
          message.error("Failed to load teachers");
        });
    };
  
    const addTeacher = () => {
        const updatedTeachers = [teacherID];
        const updatedBatch = { ...drawerActionID.data, teachers: updatedTeachers };
        // console.log(teacherID)
        updateBatch(drawerActionID.id, updatedBatch)
            .then(() => {   
                axios.put(`https://api.myaimgenius.com/teacher/${typeof(teacherID) === String ? teacherID : teacherID}/batch/${drawerActionID.data.name}`).then((e)=>{
                    message.success("Teacher added successfully");
                    setDrawerActionID({ ...drawerActionID, data: updatedBatch });
                    setBatches(batches.map(b => b.id === drawerActionID.id ? updatedBatch : b));
                }).catch(() => {
                    message.error("Failed to add D");
                });
            })
            .catch(() => {
                message.error("Failed to add teacher");
            });
    };

    useEffect(() => {
        getAllBatchesWithStudents()
            .then(batches => {
                setBatches(batches);
                setLoading(false);
            })
            .catch(() => {
                message.error("Failed to load batches");
            });
            
fetchTeachers()

    }, []);

    return (
        <div className="p-8 m-4 rounded-xl min-h-[80vh] bg-[#343a40]">
        <Row gutter={24}>
  {/* Create New Batch */}
  <Col span={6}>
 
    <Card
      className="bg-gray-800  drop-shadow-xl shadow-gray-700 rounded-xl text-white"
      bordered={false}
    >
       <h1 className='mb-4 text-2xl font-bold'>New Batch</h1>
      <form
        onSubmit={e => {
          e.preventDefault();
          createBatch(newBatchName, newBatchFee, newBatchInstallments, newBatchID)
            .then(() => {
              message.success("Batch Created Successfully");
              return getAllBatchesWithStudents();
            })
            .then(batches => setBatches(batches))
            .catch(() => message.error("Failed to create batch"));
        }}
      >
        <div className="mb-4 space-y-4">
          <div className="flex space-x-4">
            <input
              value={newBatchName}
              onChange={e => setNewBatch(e.target.value)}
              required
              type="text"
              placeholder="Batch Name"
              className="w-full rounded-xl p-3 border-2 border-gray-600 bg-gray-800 text-white focus:ring-2 focus:ring-blue-500 transition"
            />
            <input
              value={newBatchID}
              onChange={e => setNewBatchID(e.target.value)}
              required
              type="text"
              placeholder="Batch ID"
              className="w-full rounded-xl p-3 border-2 border-gray-600 bg-gray-800 text-white focus:ring-2 focus:ring-blue-500 transition"
            />
          </div>
          <div className="flex space-x-4">
            <input
              value={newBatchFee}
              onChange={e => setNewBatchFee(e.target.value)}
              required
              type="number"
              placeholder="Batch Fee"
              className="w-full rounded-xl p-3 border-2 border-gray-600 bg-gray-800 text-white focus:ring-2 focus:ring-blue-500 transition"
            />
            <input
              value={newBatchInstallments}
              onChange={e => setNewBatchInstallments(e.target.value)}
              required
              type="number"
              placeholder="Installments"
              className="w-full rounded-xl p-3 border-2 border-gray-600 bg-gray-800 text-white focus:ring-2 focus:ring-blue-500 transition"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-600 text-white rounded-xl py-3 mt-4 hover:bg-blue-700 transition"
          >
            Create
          </button>
        </div>
      </form>
    </Card>
  </Col>

  {/* Batches List */}
  <Col span={18}>
    <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
      {batches.map(batch => (
        <li className="col-span-1 divide-y divide-gray-700 rounded-xl drop-shadow-xl shadow-gray-700 bg-gray-800 shadow-md text-white" key={batch.id}>
          <div className="flex w-full items-center justify-between space-x-6 p-6">
            <div className="flex-1 truncate">
              <div className="flex items-center space-x-3">
                <h3
                  className="cursor-pointer text-xl font-semibold hover:text-blue-500"
                  onClick={() => {
                    setDrawerActionID({ id: batch.id, action: "edit", data: batch });
                    showStudentModal(batch.students);
                  }}
                >
                  {batch.name}
                </h3>
               
              </div>
              <span
                  className={`mt-2 inline-flex items-center rounded-full px-3 py-1 text-xs font-medium ${
                    batch.active
                      ? 'bg-green-50 text-green-800'
                      : 'bg-red-50 text-red-800'
                  }`}
                >
                  {batch.active ? 'Active' : 'Inactive'}
                </span>
              <p className="mt-2 text-sm text-gray-400">Total Students: {batch.students.length}</p>
            </div>
          </div>
          <div className="flex -mt-px divide-x divide-gray-700">
            <div className="flex w-0 flex-1">
              <button
                onClick={() => showDrawer(batch)}
                className="flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-200 hover:text-white transition"
              >
                <EditOutlined className="h-5 w-5" />
                Edit
              </button>
              <Popconfirm
                title="Are you sure?"
                onConfirm={() => {
                  axios
                    .post(`https://api.myaimgenius.com/admin/batch/${batch._id}/installment`)
                    .then(() => {
                      message.info("Installment Added!");
                    })
                    .catch(() => {
                      message.error("Error adding Installment");
                    });
                }}
              >
                <button
                  className="flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-200 hover:text-white transition"
                >
                  <DollarCircleOutlined className="h-5 w-5" />
                  Add Installment
                </button>
              </Popconfirm>
            </div>
          </div>
        </li>
      ))}
    </ul>
  </Col>
</Row>

        <Drawer width={window.innerWidth * 0.5} title={`Batch: ${drawerActionID.data.name}`} onClose={onClose} open={open}>
        <div className="relative flex flex-col w-full h-full text-slate-700 bg-white shadow-md rounded-xl bg-clip-border">
  <div className="relative mx-4 mt-4 overflow-hidden text-slate-700 bg-white rounded-none bg-clip-border">
    <div className="flex items-center justify-between">
    </div>
    <Card className='shadow-lg'>
      <p className='m-1'>Name: {drawerActionID.data.name}</p>
      <p className='m-1'>Fee: {drawerActionID.data.totalFee}</p>
      <p className='m-1'>Installments: {drawerActionID.data.installments}</p>
      <p className='m-1'>Active: <Switch checked={drawerActionID.data.active} onChange={toggleBatchActive} /></p>
      <br />
      <span>Select Batch Level</span>
      <Select value={drawerActionID.data.level} onChange={(e)=>{changeBatchLevel(e) }} className="w-full">
        <Option value="A1">A1</Option>
        <Option value="A2">A2</Option>
        <Option value="B1">B1</Option>
      </Select>
    </Card>
  </div>

  <Divider>Students</Divider>
  <div className="p-0 overflow-scroll">
    <table className="w-full mt-4 text-left table-auto min-w-max">
      <thead>
        <tr>
          <th className="p-4 transition-colors cursor-pointer border-y border-slate-200 bg-slate-50 hover:bg-slate-100">Student</th>
          <th className="p-4 transition-colors cursor-pointer border-y border-slate-200 bg-slate-50 hover:bg-slate-100">ID</th>
          <th className="p-4 transition-colors cursor-pointer border-y border-slate-200 bg-slate-50 hover:bg-slate-100">Action</th>
        </tr>
      </thead>
      <tbody>
        {drawerActionID.data.students!== undefined ? drawerActionID.data.students.map(student => (
          <tr key={student._id}>
            <td className="p-4 border-b border-slate-200">
              <div className="flex items-center gap-3">
                <p className="text-sm font-semibold text-slate-700">{`${student.firstName} ${student.lastName}`}</p>
              
              </div>
            </td>
            <td className="p-4 border-b border-slate-200">
            <p className="text-sm text-slate-500">{student.studentID}</p>
            </td>
            <td className="p-4 border-b border-slate-200">
              <Popconfirm title="Are you sure to remove this student?" onConfirm={() => removeStudent(student._id)}>
                <a className="text-red-500">Remove</a>
              </Popconfirm>
            </td>
          </tr>
        )) :  <></>}
      </tbody>
    </table>
  </div>

  <Divider>Assign Teacher</Divider>
  <div className='p-4 m-4'>
  Assigned Teacher ID: <b>{drawerActionID.data.teachers !== undefined ? drawerActionID.data.teachers[0] : "None"}</b>
  <br></br>
  <br></br>
  <Select onChange={(e) => setTeacherID(e)} className='w-full'>
    {teachersList.map(e => (
    drawerActionID.data.teachers !== undefined && e._id !== drawerActionID.data.teachers[0] ?
      <Option key={e._id} value={e._id}> <b>{e.name}</b> <span className='text-gray-500 text-xs ml-6'>{e._id}</span> </Option> : <></>
    ))}
  </Select>
  <br/>
  <br/>  
  <Button disabled={teacherID === "" ? true : false} onClick={addTeacher} className='p-2 bg-blueGray-100'>Assign Teacher</Button>
  
  </div>
  <br />
  <br />
<Divider>Sensitive Area</Divider>
<div className="p-5">
  <Popconfirm title={"Are you sure to delete the batch "+drawerActionID.data.name+ "?"} onConfirm={(e)=>{
     deleteBatch(drawerActionID.data._id).then((e)=>{
      
      getAllBatchesWithStudents()
      .then(batches => {
          setBatches(batches);
          setOpen(false)
      })
      .catch(() => {
          message.error("Failed to load batches");
      });
    }).catch((e)=>{
        message.error("Error deleting the batch")
      })
  }}>
  <button className='bg-red-400 rounded-lg px-3 py-2 text-white'>
    Delete Batch
  </button>
  </Popconfirm>
</div>
</div>

        </Drawer>
<ConfigProvider
theme={{
  components: {
    Modal: {
      contentBg: "#343a40",
      titleColor:"#fff",
      footerBg: "#343a40",
      headerBg: "#343a40",
    },
  },
}}>
<Modal
      title={<h2 className="text-white">Batch Students</h2>}
      visible={studentModalOpen}
      width={"35vw"}
      onCancel={closeStudentModal}
      footer={<Button onClick={closeStudentModal} className="bg-blue-600 text-white hover:bg-blue-700">Close</Button>}
      
      bodyStyle={{ backgroundColor: "#343a40", color: "white", padding: "16px" }}
    >
      <table className="w-full mt-4 text-left table-auto  rounded-xl border-collapse">
        <thead className=' rounded-xl'>
          <tr className=' rounded-xl'>
            <th className="p-4 text-white border-y border-gray-700 bg-gray-800">Student</th>
            <th className="p-4 text-white border-y border-gray-700 bg-gray-800">ID</th>
          </tr>
        </thead>
        <tbody>
          {drawerActionID.data?.students ? (
            drawerActionID.data.students.map((student) => (
              <tr key={student._id}>
                <td className="p-4 border-b border-gray-700">
                  <div className="flex items-center gap-3">
                    <p className="text-sm font-semibold text-white">{`${student.firstName} ${student.lastName}`}</p>
                  </div>
                </td>
                <td className="p-4 border-b border-gray-700">
                  <p className="text-sm text-gray-400">{student.studentID}</p>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="2" className="p-4 text-center text-gray-400">
                No students found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </Modal>

</ConfigProvider>
      
      </div>
      
    );
};

export default Batches;
