import { useEffect, useState } from "react";
import { Card, Col, Divider, Progress, Row, Tag } from "antd";
import { Pie, Bar } from 'react-chartjs-2';
import CalendarAD from "./Calendar";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js';
import axios from "axios";
import TodoApp from "./ToDo";

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title);

const AdminHome = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [clD, setCld] = useState("")
  const [todoDom, setTodoDom] = useState(false)

  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  useEffect(() => {
    // Fetch data from the backend
    const fetchData =  () => {
      
    axios.get('https://api.myaimgenius.com/admin/overview').then((e)=>{
      console.log(e)
      setData(e.data);

      setLoading(false);
        }).catch((error)=>{

          console.error('Error fetching dashboard data:', error);
          setLoading(false);
        })
        
      
      
    };

    fetchData();

    let d = new Date
    setCld(months[d.getMonth()])
  }, []);

  if (loading) {
    return <div class="w-full h-full  top-0 left-0 bg-white flex items-center justify-center">
   Loading<svg
  fill="none"
  className="w-6 h-6 animate-spin"
  viewBox="0 0 32 32"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    clipRule="evenodd"
    d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
    fill="currentColor"
    fillRule="evenodd"
  />
</svg>

  </div>   
  ;
  }

  if (!data) {
    return <div>No data available</div>;
  }

  const { fees, attendance, recentBatches, recentStudents, recentPayments, recentExpenses } = data;

  const paymentData = {
    labels: ['Unpaid', 'Paid', 'Partial Payments'],
    datasets: [
      {
        label: 'Payment Status',
        data: [fees.unpaidFees, fees.paidFees, fees.partialPaidFees],
        backgroundColor: ['rgba(255, 99, 132, 0.6)', 'rgba(54, 162, 235, 0.6)', 'rgba(255, 206, 86, 0.6)'],
        borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)'],
        borderWidth: 1,
      },
    ],
  };

  const attendanceData = {
    labels: ['Present Students', 'Late Students', 'Absent Students'],
    datasets: [
      {
        label: 'Number of Students',
        data: [attendance.studentsPresent, attendance.studentsLate, attendance.absentStudents],
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  return (
    <section>
    <div className="px-4  py-4 mx-auto">
      <div className="flex  bg-[#343a40] rounded-xl p-2">
     
      <div className="w-full  drop-shadow-xl shadow-gray-700 md:w-1/2 lg:w-1/2 xl:w-1/2 mx-2 mt-3 mb-2">
          <div className="flex p-3 space-x-2">
            {/* Fees Overview */}
            <Card className=" drop-shadow-xl shadow-gray-700 bg-[#fff] w-full">
              <h1 className="uppercase text-md text-black flex">
                <p className="w-2/3">Awaiting Payment: </p>
                <p className="w-1/3 text-right justify-right align-right">
                  {fees.awaitingPayments}/{fees.unpaidFees + fees.paidFees + fees.partialPaidFees}
                </p>
              </h1>
              <Progress
                percent={((100 * fees.awaitingPayments) / (fees.unpaidFees + fees.paidFees + fees.partialPaidFees)).toFixed(0)}
                className="mx-auto text-black"
                size={[, 20]}
                strokeColor="#B7EB8F"
              />
            </Card>
  
            <Divider type="vertical" />
  
            {/* Attendance Overview */}
            <Card className="bg-[#fff] b-0 w-full">
              <h1 className="uppercase text-md text-black flex">
                <p className="w-2/3">Students Present Today: </p>
                <p className="w-1/3 text-right justify-right align-right">
                  {attendance.studentsPresent}/{attendance.studentsPresent + attendance.absentStudents}
                </p>
              </h1>
              <Progress
                percent={((100 * attendance.studentsPresent) / (attendance.studentsPresent + attendance.absentStudents)).toFixed(0)}
                className="mx-auto"
                size={[, 20]}
                strokeColor="#C4F4FF"
              />
            </Card>
          </div>
  
          {/* Pie Charts and Bar Graph */}
          <div className="mt-4  p p-2 flex flex-wrap rounded-2xl">
            <div className="mx-auto md:w-full  drop-shadow-xl shadow-gray-700 mx-1 mb-4">
              <Card className=" drop-shadow-xl shadow-gray-700  mx-auto bg-[#fff] flex text-black" title={<p className="text-black">FEES OVERVIEW</p>}>
                <Pie data={paymentData}  />
              </Card>
            </div>
            <div className="mx-auto md:w-full  drop-shadow-xl shadow-gray-700 mx-1 mb-4">
              <Card className=" drop-shadow-xl shadow-gray-700 mx-auto bg-[#fff] flex text-black" title={<p className="text-black">TODAY'S ATTENDANCE</p>}>
                <Bar data={attendanceData} />
              </Card>
            </div>
            
          </div>
          
        </div>

        <div className="w-full  drop-shadow-xl shadow-gray-700 md:w-1/2 lg:w-1/2 xl:w-1/2 mx-2 mt-3 mb-2">
        <TodoApp /> 
          
        </div>
        
      </div>
    </div>
  </section>
  
  );
};

export default AdminHome;
